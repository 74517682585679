import { WebViewerInstance } from '@pdftron/webviewer';

export async function getMiddleRightCoordinates(instance: WebViewerInstance, pageNum?: number): Promise<{ x: number; y: number }> {
    const currentPageNumber = pageNum ?? instance.Core.documentViewer.getCurrentPage();
    
    const height = instance.Core.documentViewer.getPageHeight(currentPageNumber);
    const width = instance.Core.documentViewer.getPageWidth(currentPageNumber);

    const x = width - 40;
    const y = height / 2;

    return { x, y };
}
