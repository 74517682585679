import React, { useContext, useEffect, useMemo, useState } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons';
import { Flex, Modal, Space, Tooltip, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { PdfViewerContext2 } from '@/App.tsx';
import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { CUSTOM_MODAL_WIDTH } from '@/constants/modalWidth.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { addAnnotationWithComment } from '@/utils/pdfViewer/addAnnotationWithComment.ts';

import { CommentsLibraryProps } from './CommentsLibrary.types'

export const CommentsLibrary = (props: CommentsLibraryProps) => {
    const { stepConfig, onClose } = props
    const [selectedComment, setSelectedComment] = useState<string | null>(null)
    const { pdfInstance } = useContext(PdfViewerContext2)
    const { annotationManager } = useViewerDocument()
    const authData = useContext<AuthData>(AuthDataContext)

    const [inputValue, setInputValue] = useState('')

    const handleAddNote = () => {
        if(!pdfInstance || !annotationManager) return

        addAnnotationWithComment(pdfInstance, annotationManager, authData.user.displayName, inputValue)

        onClose()
    }

    const commentOptions = useMemo(() => {
        return Object.entries(stepConfig?.suggestedMessageTemplates || {}).map(([value, label]) => ({
            value,
            label: (label.suggestedMessageTemplate || label.explanationTemplate).replace(/<[^>]*>/g, '').replace(/\{\{(.*?)\}\}/g, '[$1]'),
        })).filter((option) => option.label?.length)
    }, [stepConfig])

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(e.target.value)
    }
    
    useEffect(() => {
        if(selectedComment) {
            setInputValue(commentOptions.find((option) => option.value === selectedComment)?.label || '')
        }
    }, [selectedComment]);
    
    return (
        <Modal
            open
            confirmLoading={false}
            title={(
                <Flex gap={8} align='baseline'>
                    <Typography.Title level={4}>
                    Comments Library
                    </Typography.Title>
                    <Tooltip title='The Comments Library feature offers an extensive collection of financial statement comment templates and detailed guidance on applying financial reporting standards'>
                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>
                    </Tooltip>
                </Flex>
            )}
            centered
            onOk={handleAddNote}
            onCancel={onClose}
            width={CUSTOM_MODAL_WIDTH.medium}
            okButtonProps={{
                disabled: !selectedComment,
            }}
            okText='Add Comment'
            styles={{
                body: {
                    overflowY: 'auto',
                },
                content: {
                    overflowY: 'hidden',
                    maxHeight: '90vh',
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <Flex vertical gap={8}>
                {!selectedComment && (
                    <>
                        <Flex gap={4} align='baseline'>
                            <Typography.Title level={5}>
                            Choose Template
                            </Typography.Title>
                            {/*<Tooltip title='Here is a comment that will be created'>*/}
                            {/*    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>*/}
                            {/*</Tooltip>*/}
                        </Flex>
                        <Space>
                            <Flex vertical gap={8}>
                                {commentOptions.map((option) => (
                                    <Flex 
                                        style={{
                                            border: '1px solid',
                                            borderColor: selectedComment === option.value ? 'rgb(2, 83, 255)' : 'rgb(240, 240, 240)',
                                            borderRadius: 8,
                                            padding: 8,
                                            cursor: 'pointer', userSelect: 'none' }}
                                        onClick={() => setSelectedComment(option.value)}
                                    >
                                        <Typography.Text key={option.value} >
                                            {option.label}
                                        </Typography.Text>
                                    </Flex>
                                ))}
                            </Flex> 
                            {/*<Select*/}
                            {/*    defaultActiveFirstOption*/}
                            {/*    placeholder='Select Comment'*/}
                            {/*    options={commentOptions}*/}
                            {/*    title='Comment'*/}
                            {/*    onChange={(val) => {*/}
                            {/*        setSelectedComment(val)*/}
                            {/*    }}*/}
                            {/*    style={{ width: 500 }}*/}
                            {/*/>*/}
                        </Space>
                    </>
                )}

                {Boolean(selectedComment) && (
                    <>
                        <Flex gap={4} align='baseline'>
                            <Typography.Title level={5}>
                            Edit Template
                            </Typography.Title>
                            <Tooltip title='Here is a comment that will be created'>
                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>
                            </Tooltip>
                        </Flex>

                        <TextArea
                            style={{ minHeight: 200 }}
                            onChange={handleInputChange}
                            autoSize
                            value={inputValue}
                        />
                    </>
                )}
            </Flex>
        </Modal>
    )
}
