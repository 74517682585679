import { useContext, useEffect } from 'react';

import { and, doc, query, where } from 'firebase/firestore';
import { useCollectionData, useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { CustomDataKey } from '@/constants/pdfViewer/customDataKey.ts';
import { ReportAnnotation, reportAnnotationRef } from '@/firestore/api/reportAnnotation.ts';
import { ReportReview, reportReviewRef } from '@/firestore/api/reportReview.ts';
import { ReviewIdentifiedBlockConfidence } from '@/firestore/api/reviewIdentifiedBlock.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { AnnotationChangeSource } from '@/types/pdfLib/annotationChangeSource.ts';

export const useApplyAnnotations = () => {
    const { id: docId } = useParams()

    const { annotationManager } = useViewerDocument()

    const [reportSnapshot, reportItemLoading ] = useDocument<ReportReview>(doc(reportReviewRef, docId))
    
    const authData = useContext<AuthData>(AuthDataContext)

    const annotationCollectionFilter = query(reportAnnotationRef, and(
        where('companyId', '==', authData.company.id),
        where('reportId', '==', docId),
    ))
    
    const [reportAnnotationData, reportAnnotationDataLoading] = useCollectionData<ReportAnnotation>(
        annotationCollectionFilter,
    )
    
    const reportData = reportSnapshot?.data()
    
    const deleteAnnotForOtherSteps = ( activeStep: string) => {
        const annotations = annotationManager.getAnnotationsList()

        console.log('annotations', annotations)

        // Delete for other steps
        const toDelete = annotations
            .filter((annotation) => {
                const stepFinished = reportData?.reviewStatus !== 'inProgress'
                const confidence = annotation.getCustomData('tickConfidence') as ReviewIdentifiedBlockConfidence
                const excludeFromSummary = confidence === 'neutral' || confidence === 'valid' || confidence === 'link'

                console.log('identified conf', confidence)

                if(stepFinished) {
                    return excludeFromSummary
                } else {
                    const step = annotation.getCustomData('relatedStep')

                    return step?.length && (step !== activeStep)
                }
            })

        const namesToDelete = toDelete.map((annotation) => annotation.getCustomData('toolName'))

        annotationManager?.deleteAnnotations(toDelete, {
            source: AnnotationChangeSource.temporaryDelete,
        })
    }

    const createNotAppliedAnnotations = async (activeStep: string) => {
        const annotations = annotationManager?.getAnnotationsList() || []
        const annotationsApplied = annotations.map((annotation) => annotation.Id)

        const regexToolName = new RegExp(`${CustomDataKey.toolName}:([a-zA-Z_]+)`);

        const annotWIthoutStep = reportAnnotationData
            ?.filter((annotaion) => {
                return !annotaion.stepKey;
            }) || []
        
        const annotationsNames = annotWIthoutStep.map((annotation) => annotation.anotation.match(regexToolName)?.[1])

        const annotForCurrentStep = reportAnnotationData
            ?.filter((annotaion) => {
                return activeStep && (annotaion.stepKey === activeStep);
            }) || []
        
        const annotationsForSummaryReport = reportAnnotationData
            ?.filter((reportAnnotatation) => {
                const stepFinished = reportData?.reviewStatus !== 'inProgress'

                if(!stepFinished) return false
                
                const regexConf = new RegExp(`${CustomDataKey.tickConfidence}:([a-zA-Z_]+)`);

                const normString = reportAnnotatation.anotation.replace(/&quot;/g, '')

                const confMatch = normString.match(regexConf);
                const nameMatch = normString.match(regexToolName);

                const confidence = confMatch?.[1] as ReviewIdentifiedBlockConfidence | undefined
                const toolName = nameMatch?.[1]

                console.log('nameMatch', toolName)
                console.log('confidence', confidence)

                if(!confidence) return true
                
                const res = (confidence === 'invalid') ? true : false

                return res
            }) || []

        console.log('annot for summary', annotationsForSummaryReport)

        const toCreate =
                [...annotWIthoutStep, ...annotForCurrentStep, ...annotationsForSummaryReport]
                    .filter((annotationData) => {
                        return !annotationsApplied.includes(annotationData.annotationId);
                    })

        for (const annotationData of toCreate) {
            const annotations = await annotationManager?.importAnnotations(annotationData.anotation);
            annotations.forEach(a => {
                annotationManager?.redrawAnnotation(a);
            });
        }
    }
    
    /**
     * Re-apply annotations every step change
     */
    useEffect(() => {
        (async () => {
            if(!annotationManager || reportItemLoading || !reportData) return

            const activeStep = reportData?.currentStep

            deleteAnnotForOtherSteps(activeStep)
            await createNotAppliedAnnotations(activeStep)
        })()
    }, [reportData?.currentStep, annotationManager, reportItemLoading, reportData?.reviewStatus]);
}

/**
 * Will be triggered when block loaded fisrt time
 */
export const useCreateAnnotationsForIdentifiedBlocks = () => {
    
}
