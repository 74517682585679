import {
    COMERCIAL_COMPANY_REVIEW_STEPS,
    COMERCIAL_COMPANY_REVIEW_STEPS_TREE,
} from '../../backend/configs/src/configs/steps/comercialCompanySteps.ts';
import { FUND_REVIEW_STEPS, FUND_REVIEW_STEPS_TREE } from '../../backend/configs/src/configs/steps/fundSteps.ts';
import { ReportType } from '../firestore/api/reportReview.ts';

export const getStepsByType = (type?: ReportType) => {
    if(!type) {
        return []
    }
    
    return type === 'regulatedFund'
        ? FUND_REVIEW_STEPS :
        type === 'commercialCompany' ? COMERCIAL_COMPANY_REVIEW_STEPS : []
}

export const getStepsTreeByType = (type?: ReportType) => {


    return type === 'regulatedFund'
        ? FUND_REVIEW_STEPS_TREE :
        type === 'commercialCompany' ? COMERCIAL_COMPANY_REVIEW_STEPS_TREE : []
}
