import React, { useState } from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons';
import { addDoc } from '@firebase/firestore';
import { Checkbox, Flex, Space, Tooltip, Typography } from 'antd';
import { and, query, updateDoc, where } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

import { reviewStepCheckStatusRef } from '@/firestore/api/reviewStepCheckStatus.ts';
import { SubchecksTooltip } from '@/pages/ReviewPage';

import { StepSubchecksProps } from './StepSubchecks.types'
import { ReviewStepSubCheck } from '../../../../../../../backend/configs/src/configs/steps/steps.types.ts';

export const StepSubchecks = (props: StepSubchecksProps) => {
    const { stepConfig, reportSnap } = props

    const [checksState, setChecksState] = useState<Record<string, boolean>>({})
    
    const checksQuery = query(reviewStepCheckStatusRef, and(
        where('reportId', '==', reportSnap.id),
        where('stepKey', '==', stepConfig.key),
    ))
    const [checksSnapshot] = useCollection<ReviewStepSubCheck>(checksQuery)

    if(!stepConfig.subChecks?.length) return null

    return (
        <>
            <Flex gap={4} justify='baseline'><SubchecksTooltip/><Typography.Text strong>Subchecks</Typography.Text></Flex>
            <Flex gap={4} vertical>
                {stepConfig.subChecks.map((block) => {
                    const checkSnap = checksSnapshot?.docs.find((doc) => doc.data().checkKey === block.key)
                    const checkData = checkSnap?.data()
                    const checkRef = checkSnap?.ref

                    const checked = checksState[block.key] ?? checkData?.checked ?? false
                    
                    return (
                        <Space>
                            <Checkbox
                                key={block.key}
                                onChange={async (e) => {
                                    setChecksState((prev) => ({
                                        ...prev,
                                        [block.key]: e.target.checked,
                                    }))
                                    if(checkRef) {
                                        await updateDoc(checkRef, { checked: e.target.checked })
                                    } else {
                                        await addDoc(reviewStepCheckStatusRef, {
                                            companyId: reportSnap.data().companyId,
                                            reportId: reportSnap.id,
                                            checkKey: block.key,
                                            stepKey: stepConfig.key,
                                            checked: e.target.checked,
                                        })
                                    }
                                }}
                                checked={checked}
                            >
                                {block.title}
                            </Checkbox>
                            {block.explanation?.trim().length > 0 && (
                                <Tooltip title={block.explanation.trim()}>
                                    <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>
                                </Tooltip>
                            )}
                        </Space>
                    )
                })}
            </Flex>
        </>
    )
}
