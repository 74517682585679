/**
 * Normalize shortened (mm-dd-yyyy) absolute date from back to local date
 * We need it for correct formating and datepickers
 */
export const normalizeShortBackDate = (date: string | Date): Date => {
    const utcDate = new Date(date);

    const offsetMinutes = utcDate.getTimezoneOffset();

    const localDate = new Date(utcDate.getTime() - offsetMinutes * 60 * 1000);

    return localDate;
}
