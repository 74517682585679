import { useContext, useEffect, useRef } from 'react';

import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { useQueryParam } from 'use-query-params';

import { PdfViewerContext2 } from '@/App.tsx';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { identifiedBlockColorByConfidence } from '@/pages/ReviewPage';
import { SUM_SELECT_QUERY_PARAM, TICK_MARK_QUERY_CONFIG } from '@/widgets/MagicButtons/MagicButtons.constants.ts';
import { COMMENT_TOOL_QUERY_CONFIG } from '@/widgets/NativeCommentButton';
import { CustomToolNames } from '@/widgets/PdfViewer2/index.ts';
import { getQueryParam } from '@/widgets/PdfViewer2/useAnnotationsListener.ts';

export const stylesByCutsomTool = (pdfInstance: WebViewerInstance): Record<CustomToolNames, Record<string, any>> => ({
    [CustomToolNames.SumSelect]: {
        StrokeColor: new pdfInstance.Core.Annotations.Color(99, 99, 99),
        FillColor: new pdfInstance.Core.Annotations.Color(99, 99, 99),
        Opacity: 0.2,
        StrokeThickness: 1,
    },
    [CustomToolNames.TickMark]: {
        StrokeColor: identifiedBlockColorByConfidence(pdfInstance)['neutral'],
        FillColor: identifiedBlockColorByConfidence(pdfInstance)['neutral'],
        Opacity: 0.2,
        StrokeThickness: 1,
    },
    [CustomToolNames.CrossLink]: {
        StrokeColor: identifiedBlockColorByConfidence(pdfInstance)['link'],
        FillColor: identifiedBlockColorByConfidence(pdfInstance)['link'],
        Opacity: 0.2,
        StrokeThickness: 1,
    },
})

const getDefaultTool = (pdfInstance: WebViewerInstance) => pdfInstance.Core.Tools.ToolNames.TEXT_SELECT

/**
 * Handle tools activation for magic panel
 *
 * TODO: Styles can saved inside tool configuration
 * TODO: Listen panel event and deactivate tools query if tool deactivated by hotkey or ESC
 */
export const useToolsListener = () => {
    const [isSumSelectOpen, setIsSumSelectOpen] = useQueryParam(SUM_SELECT_QUERY_PARAM.name, SUM_SELECT_QUERY_PARAM.type)
    const [isTickMarkOpen, setIsTickMarkOpen] = useQueryParam(TICK_MARK_QUERY_CONFIG.name, TICK_MARK_QUERY_CONFIG.type)
    const [isCommentToolOpen, setIsCommentToolOpen] = useQueryParam(COMMENT_TOOL_QUERY_CONFIG.name, COMMENT_TOOL_QUERY_CONFIG.type)
    const isInitialTool = useRef(true)
    
    const { pdfInstance } = useContext(PdfViewerContext2)
    
    const { documentViewer } = useViewerDocument()

    useEffect(() => {
        if(!documentViewer || !pdfInstance) return

        const defaultToolName = getDefaultTool(pdfInstance)

        const callback = (tool) => {
            // Turn off any non-default tool
            if(isInitialTool.current) {
                isInitialTool.current = false

                if(defaultToolName !== tool.name) {
                    pdfInstance.UI.setToolMode(defaultToolName)
                }
            }

            // Taking params directly because can't add them into hook args since it will trigger event update
            const isTickMarkOpen = getQueryParam(TICK_MARK_QUERY_CONFIG.name) === '1'
            const isSumSelectOpen = getQueryParam(SUM_SELECT_QUERY_PARAM.name) === '1'
            const isCommentToolOpen = getQueryParam(COMMENT_TOOL_QUERY_CONFIG.name) === '1'

            // If custom tool turned off by clicking on panel or hotkey
            if(isTickMarkOpen && tool.name !== CustomToolNames.TickMark) {
                setIsTickMarkOpen(false)
            } else if (isSumSelectOpen && tool.name !== CustomToolNames.SumSelect) {
                setIsSumSelectOpen(false)
            } else if(isCommentToolOpen && tool.name !== pdfInstance.Core.Tools.ToolNames.STICKY) {
                setIsCommentToolOpen(false)
            }
        }

        documentViewer.addEventListener('toolModeUpdated', callback)
        documentViewer.addEventListener('toolUpdated', callback)

        return () => {
            documentViewer.removeEventListener('toolModeUpdated', callback)
        }
    }, [documentViewer, pdfInstance]);
    
    useEffect(() => {
        if(!pdfInstance) return
        
        const docViewer = pdfInstance.docViewer as Core.DocumentViewer

        const style = stylesByCutsomTool(pdfInstance)
        
        if(isTickMarkOpen) {
            const tool = docViewer.getTool(CustomToolNames.TickMark);
            tool.setStyles({
                ...style[CustomToolNames.TickMark],
            })
            tool.addEventListener('annotationAdded', annotation => {
                annotation.NoDelete = false;
                annotation.NoMove = true;
                annotation.NoResize = true;
            });

            pdfInstance.UI.setToolMode(CustomToolNames.TickMark);
        } else if(isSumSelectOpen) {
            const tool = docViewer.getTool(CustomToolNames.SumSelect);
            tool.setStyles({
                ...style[CustomToolNames.SumSelect],
            });
            tool.addEventListener('annotationAdded', annotation => {
                annotation.NoDelete = false;
                annotation.NoMove = true;
                annotation.NoResize = true;

                annotation.setRotationControlEnabled(false);
            });

            pdfInstance.UI.setToolMode(CustomToolNames.SumSelect);
        } else if(isCommentToolOpen) {
            pdfInstance.UI.setToolMode(pdfInstance.Core.Tools.ToolNames.STICKY);
        } else {
            pdfInstance.UI.setToolMode(getDefaultTool(pdfInstance));
        }
    }, [pdfInstance, isTickMarkOpen, isSumSelectOpen, isCommentToolOpen])
}
