import { useContext, useState } from 'react'

import {
    CommentOutlined,
    FilePdfOutlined,
} from '@ant-design/icons';
import { Flex, Menu } from 'antd';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';

import { GuidePanelProps } from './GuidePanel.types'
import { Messages } from './Messages';
import { StepGuide } from './Steps';

export const GuidePanel = (props: GuidePanelProps) => {
    const { reviewItemData } = props
    const authData = useContext<AuthData>(AuthDataContext)

    const [navItems, setNavItems] = useState(['guidedReview']);

    const withVelt = authData.company?.data()?.features?.includes('velt')

    return (
        <Flex style={{ flexGrow: 1, flexDirection: 'column', overflowX: 'hidden' }}>
            {/* Hide panel button */}
            {/*<Button type='text' icon={<MenuUnfoldOutlined/>} style={{ margin: '4px 0 4px 6px' }}/>*/}
            {/*<Divider style={{ margin: 0 }}/>*/}

            <Menu
                onClick={(item) => {
                    setNavItems([item.key])
                }}
                selectedKeys={navItems}
                mode='horizontal'
                items={
                    [
                        {
                            label: 'Review',
                            key: 'guidedReview',
                            icon: <FilePdfOutlined/>,
                        },
                        (withVelt ? ({
                            label: 'Comments',
                            key: 'comments',
                            icon: <CommentOutlined/>,
                        }) : null ),
                    ].filter(Boolean)
                }
            />
            <Flex style={{ overflowX: 'auto', height: '100%' }}>
                {navItems.includes('guidedReview') && (
                    <StepGuide reviewItemData={reviewItemData}/>
                )}
                {navItems.includes('comments') && (
                    <Messages/>
                )}
            </Flex>
        </Flex>
    )
}
