export type FileFormat = 'pdf' | 'xlsx' | 'xls' | 'doc' | 'docx' | 'csv'

export const FILE_TYPE_BY_FORMAT: Record<FileFormat, string> = {
    pdf: 'application/pdf',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    csv: 'text/csv',
}

export const FILE_FORMAT_BY_TYPE = Object.entries(FILE_TYPE_BY_FORMAT).reduce((acc, [key, value]) => {
    acc[value] = key as FileFormat
    return acc
}, {} as Record<string, FileFormat>)
