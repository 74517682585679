import { collection } from 'firebase/firestore';

import { db } from '../config.ts';

export interface CompanyEntity {
    name: string
    /**
     * Parent company
     */
    clientCompanyId: string

    companyId: string
}

export const companyEntityRef = collection(db, 'companyEntity')
