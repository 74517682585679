import { collection } from 'firebase/firestore';

import { db } from '../config.ts';

export interface ResolvedComment {
    commentId: string

    status: 'applied' | 'rejected'

    companyId: string
    reportOnReviewId: string
}

export const resolvedCommentRef = collection(db, 'resolvedComment')
