import { FormItemProps } from 'antd';
import { collection } from 'firebase/firestore';

import { db } from '../config.ts';

/**
 * Use it for form/table etc
 */
export const REG_REP_FORM_ITEMS: Record<Partial<keyof RegRepProcess>, FormItemProps> = {
    status: {
        label: 'Status',
        name: 'status',
        required: true,
    },
    fileS213: {
        label: 'File S2.13',
        name: 'fileS213',
        required: false,
        // rules: [{
        //     message: 'Please upload S213 file',
        // }],
    },
    fileTPTOBS: {
        label: 'File TPTOBS',
        name: 'fileTPTOBS',
        required: true,
        // rules: [{
        //     message: 'Please upload TPTOBS file',
        // }],
    },
    fileU11: {
        label: 'File U1.1',
        name: 'fileU11',
        required: true,
        // rules: [{
        //     message: 'Please upload TPTOBS file',
        // }],
    },
}

export type RegRepProcessStatus =
    /**
     * Initial status
     */
    'processing' |
    /**
     * Processing is failed
     */
    'error' |
    /**
         * Processing is successfully finished
         */
    'done'

export type RegRepProcessValidationStatus =
    'processing' | 'warning' | 'failed' | 'noErrors'

export interface RegRepProcessFile {
    name: string,
    file: string
}

export interface RegRepProcess {
    companyId: string
    userId: string
    
    status: RegRepProcessStatus

    validationStatus: RegRepProcessValidationStatus

    /**
     * Input files s213 pipeline
     */
    fileS213: string
    fileTPTOBS: string
    fileU11: string

    /**
     * Output files s213 pipeline
     */
    fileS213Errors: RegRepProcessFile,
    fileS0213Converted: RegRepProcessFile,
    fileU11Errors: RegRepProcessFile,
    fileU11Converted: RegRepProcessFile,
    fileTPTErrors: RegRepProcessFile,
    fileTPTConverted: RegRepProcessFile,
    fileS213TPTOBSErrors: RegRepProcessFile,
    fileU11TPTOBSErrors: RegRepProcessFile

    uploadedAt: Date
}

type OutputFileKeys = Extract<keyof RegRepProcess, 
'fileS213Errors' | 
'fileS0213Converted' | 
'fileU11Errors' | 
'fileU11Converted' | 
'fileTPTErrors' | 
'fileTPTConverted' | 
'fileS213TPTOBSErrors' | 
'fileU11TPTOBSErrors'>;

export const regRepResultFilesKeys : OutputFileKeys[] = [
    'fileS213Errors',
    'fileS0213Converted',
    'fileU11Errors',
    'fileU11Converted',
    'fileTPTErrors',
    'fileTPTConverted',
    'fileS213TPTOBSErrors',
    'fileU11TPTOBSErrors',
];

export const REG_REP_PROCESS_COLLECTION = 'regRepProcess'

export const regRepProcessRef = collection(db, 'regRepProcess')
