import { collection } from 'firebase/firestore';

import { db } from '../config.ts';

export interface ClientCompany {
    name: string
    companyId: string
}

// TODO: Rename to userClientRef
export const clientCompanyRef = collection(db, 'clientCompany')
