import { useContext, useEffect, useState } from 'react';

import { addDoc, DocumentSnapshot } from '@firebase/firestore';
import { DatePicker, Divider, Form, FormItemProps, Input, Modal, Select } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import { message } from 'antd/lib';
import { format } from 'date-fns/format';
import dayjs from 'dayjs';
import { doc, getDoc, query, updateDoc, where } from 'firebase/firestore';
import { ref as storageRef } from 'firebase/storage';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { useCollection, useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { useUploadFile } from 'react-firebase-hooks/storage';
import { useNavigate, useParams } from 'react-router-dom';

import DocumentData = firebase.firestore.DocumentData;
import { FileDragger } from '../../../components/base/FileDragger';
import { AuthData, AuthDataContext } from '../../../components/containers/AuthContext';
import { CUSTOM_MODAL_WIDTH } from '../../../constants/modalWidth.ts';
import { ROUTES_CONFIG, ROUTES_CONFIG_REG_REP } from '../../../constants/routes.ts';
import { clientCompanyRef } from '../../../firestore/api/clientCompany.ts';
import { companyRef } from '../../../firestore/api/company.ts';
import { companyEntityRef } from '../../../firestore/api/companyEntity.ts';
import { ReportReview, reportReviewRef, reportTypeTitles } from '../../../firestore/api/reportReview.ts';
import { db, storage } from '../../../firestore/config.ts';
import { getReviewFilepath, ReportType } from '../../../firestore/utils/getReviewFilepath.ts';

import { DATE_FNS_SERVER_DATE } from '@/constants/dates.ts';
import { normalizeShortBackDate } from '@/utils/date/normalizeShortBackDate.ts';

type NewReportFormKeys = 
    'reportFile' |
    'name' |
    'reportType' |
    'reportDate' | 
    'period' |
    'clientCompanyId' |
    'entityId' |
    'reviewer' |
    'preparer' |
    'draftNumber' |
    'suppFileRcs' |
    'suppFileMapping' |
    'suppFilePriorYearFinStatement' |
    'suppFileTrialBalance'

/**
 * @todo Save uploaded files in the folder of clientCompany/entity.
 *  Neet step by step filling fields for make it work
 */
export const FORM_ITEMS: Record<NewReportFormKeys, FormItemProps> = {
    reportFile: {
        name: ReportType.reportFile,
        label: 'Upload Report',
    },
    name: {
        name: 'name',
        label: 'Report Name',
        rules: [{
            required: true,
            message: 'Please provide a name for display in the table',
        }] },
    reportType: {
        name: 'reportType',
        label: 'Type of the Entity',
        rules: [{
            required: true,
        }] },
    reportDate: {
        name: 'reportDate',
        label: 'Period End Date',
        rules: [{
            required: true,
            message: 'Please provide a date as specified in the report',
        }],
    },
    period: {
        name: 'period',
        label: 'Period',
        rules: [{
            required: true,
        }], 
    },
    clientCompanyId: {
        name: 'clientCompanyId',
        label: 'Client',
        rules: [{
            message: 'Please  select the company associated with this report',
            required: true,
        }],
    },
    entityId: {
        name: 'entityId',
        label: 'Entity',
        rules: [{
            required: true,
            message: 'Please select the entity name associated with this report',
        }],
    },
    reviewer: {
        name: 'reviewer',
        label: 'Reviewer',
        rules: [{
            required: true,
        }],
    },
    preparer: {
        name: 'preparer',
        label: 'Preparer',
        rules: [{
            required: true,
        }] },
    draftNumber: {
        name: 'draftNumber',
        label: 'Draft Number',
        tooltip: 'If this is the first iteration, please leave it as 1. Otherwise, specify the draft number.',
        rules: [{
            required: true,
        }] },
    suppFileRcs: {
        label: 'Company Registration File: RCS file',
        name: 'suppFileRcs',
        rules: [{
            required: true,
        }], 
    },
    suppFileMapping: {
        label: 'Account Mapping File',
        name: ReportType.suppFileMapping,
    },
    suppFilePriorYearFinStatement: {
        label: 'Prior Year Financial Statements',
        name: ReportType.suppFilePriorYearFinStatement,
        rules: [{
            required: true,
        }], 
    },
    suppFileTrialBalance: {
        label: 'Trial Balance',
        name: ReportType.suppFileTrialBalance,
    },
} as const

const fieldsAvailableInEditMode: NewReportFormKeys[] = [
    'name',
    'reportDate',
    'period',
    'clientCompanyId',
    'entityId',
    'reviewer',
    'preparer',
    'draftNumber',
]

export type Period = 'annual' | 'semiAnnual' | 'quarterly' | 'monthly'

const PERIOD_SELECT_OPTIONS: Array<{value: Period, label: string}> = [
    { value: 'annual', label: 'Annual' },
    { value: 'semiAnnual', label: 'Semi-Annual' },
    { value: 'quarterly', label: 'Quarterly' },
    { value: 'monthly', label: 'Monthly' },
]

const MAX_REPORT_FILE_MB = 10

/**
 * @todo Reset on close/error
 * @todo Toast on error
 */
export const AddNewReportModal = () => {
    const { id: docId } = useParams()
    const isItEditMode = Boolean(docId)
    const navigate = useNavigate()

    const authData = useContext<AuthData>(AuthDataContext)

    const [documentForEdit, setDocumentForEdit] = useState<DocumentSnapshot<ReportReview>>()
    const [periodValue, setPeriodValue] = useState<Period>()
    const [isEditFormDataReady, setIsEditFormDataReady] = useState(false)

    useEffect(() => {
        if(docId) {
            const document = doc(db, 'reportReviews', docId)
            getDoc(document).then((doc: DocumentSnapshot<DocumentData>) => {
                if(doc.exists()) {
                    setDocumentForEdit(doc)

                    const data = doc.data()

                    const formData = fieldsAvailableInEditMode.reduce((acc, key) => {
                        if(key === 'reportDate' ) {
                            acc[key] = dayjs(normalizeShortBackDate(data[key]))

                            if(!acc.reportDate.isValid()) {
                                acc.reportDate = dayjs()
                                message.error('Report date is invalid. Please select a valid date')
                            }
                        } else {
                            acc[key] = data[key]
                        }

                        return acc
                    }, {})

                    setActiveCompanyId(data.clientCompanyId)
                    form.setFieldsValue(formData)
                    
                    setIsEditFormDataReady(true)
                }
            })
        }
    }, [docId]);

    const [uploadFile, uploading] = useUploadFile();

    const [companyEntityItems, companyEntityLoading] = useCollection(query(companyEntityRef, where('companyId', '==' , authData.company.id)));
    const [clientCompanyItems, clientCompanyLoading] = useCollection(query(clientCompanyRef, where('companyId', '==' , authData.company.id)));
    const [company, companyLoading] = useCollectionDataOnce(companyRef)

    const [acitveCopmanyId, setActiveCompanyId] = useState<string>()

    const [form] = Form.useForm();

    form.getFieldValue(FORM_ITEMS.reportFile.name)

    const handleFormSubmit = async (data: Partial<ReportReview>) => {
        const result: Partial<ReportReview> = Object.keys(data)
            .filter(key => !isItEditMode || fieldsAvailableInEditMode.includes(key))
            .reduce<Partial<ReportType>>((acc,key) => {
            if(data[key]?.toDate) { // It's a date
                acc[key] = data[key].toDate()
            } else if(data[key]?.file) { // It's a file
                acc[key] = data[key].file.response.metadata.fullPath
            } else if (typeof data[key] === 'string' || typeof data[key] === 'number') {
                acc[key] = data[key]
            }

            return acc
        }, {})

        result.reportDate = format(result.reportDate, DATE_FNS_SERVER_DATE)
        result.reportType = result.reportType || 'commercialCompany' // Use default if not set

        if(isItEditMode) {
            const editPayload: Partial<ReportReview> = {
                ...result,
            }

            await updateDoc(documentForEdit.ref, editPayload)
        } else {
            const newDocPayload: ReportReview = {
                ...result,
                status: 'initial',
                progress: 0,
                userId: authData.user.uid,
                companyId: authData.company.id,
                reviewStatus: 'inProgress',
                uploadedAt: new Date(),
            }

            await addDoc(reportReviewRef, newDocPayload)
        }
    }

    const handleOk = () => {
        form
            .validateFields()
            .then(async (values) => {
                await handleFormSubmit(values)
                form.resetFields();
                navigate(ROUTES_CONFIG.REVIEW.path)
            })
            .catch((info) => {
                message.error('Please fill in all required fields before submitting')
            })

    }

    const handleCancel = () => {
        form.resetFields();
        navigate(ROUTES_CONFIG.REVIEW.path)
    }

    const draggerCustomRequest = async (formItemProps: FormItemProps, data: RcCustomRequestOptions) => {
        data.onProgress({ percent: 0 })

        const file = data.file as RcFile

        // TODO: Make this structure more tree like. Need step by step filling fields for make it work
        const filePath = getReviewFilepath({
            uuid: file.uid,
            companyId: authData.company.id,
            reportType: formItemProps.name as ReportType,
            // @ts-expect-error
            fileType: data.file.type,
        })

        const res = await uploadFile(storageRef(storage, filePath), data.file)

        if(!res) {
            throw new Error('File upload failed')
        }

        // Save filename in form if main report file
        if (!form.getFieldValue(FORM_ITEMS.name.name) && formItemProps.name === ReportType.reportFile) {
            form.setFieldsValue({ [FORM_ITEMS.name.name]: file.name })
        }

        data.onProgress({ percent: 100 })
        data.onSuccess(res);
    }

    return (
        <Modal
            open
            confirmLoading={uploading}
            title={ROUTES_CONFIG_REG_REP.REG_REP_NEW.title}
            centered
            onOk={handleOk}
            onCancel={handleCancel}
            width={CUSTOM_MODAL_WIDTH.medium}
            okText='Upload'
            styles={{
                body: {
                    overflowY: 'auto',
                },
                content: {
                    overflowY: 'hidden',
                    maxHeight: '90vh',
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <Form
                form={form}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                onFinish={handleFormSubmit}
                autoComplete='off'
                scrollToFirstError={true}
                layout='vertical'
                disabled={isItEditMode && !isEditFormDataReady}
            >
                <Form.Item
                    {...FORM_ITEMS.reportFile}
                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.reportFile.name)}
                    rules={[{
                        required: !isItEditMode,
                        message: 'Please upload report for review',
                    }]}
                >
                    <FileDragger
                        customRequest={(options: RcCustomRequestOptions) => draggerCustomRequest(FORM_ITEMS.reportFile, options)}
                    />
                </Form.Item>
                <Form.Item
                    {...FORM_ITEMS.name}
                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.name.name)}
                >
                    <Input placeholder={FORM_ITEMS.name.label as string}/>
                </Form.Item>

                <Form.Item
                    {...FORM_ITEMS.reportType}
                    hidden={
                        (isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.reportType.name))
                || !authData.company.data()?.features?.includes('funds')}
                    rules={[{
                        required: !isItEditMode && authData.company.data()?.features?.includes('funds'),
                    }]}
                >
                    <Select
                        placeholder={FORM_ITEMS.reportType.label}
                    >
                        {Object.entries(reportTypeTitles).map(([value, label]) => (
                            <Select.Option key={value} value={value}>{label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    {...FORM_ITEMS.reportDate}
                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.reportDate.name)}
                >
                    <DatePicker/>
                </Form.Item>

                <Form.Item
                    {...FORM_ITEMS.period}
                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.period.name)}
                >
                    <Select
                        placeholder={FORM_ITEMS.period.label}
                        onSelect={(value) => {
                            setPeriodValue(value)
                        }}
                    >
                        {
                            PERIOD_SELECT_OPTIONS.map((item) => (
                                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    {...FORM_ITEMS.clientCompanyId}
                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.clientCompanyId.name)}
                >
                    <Select
                        defaultActiveFirstOption
                        placeholder={FORM_ITEMS.clientCompanyId.label}
                        loading={clientCompanyLoading}
                        onSelect={(id) => {
                            setActiveCompanyId(id)
                        }}
                    >
                        {clientCompanyItems?.docs.map((item) => (
                            <Select.Option key={item.id} value={item.id}>{item.data().name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    {...FORM_ITEMS.entityId}
                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.entityId.name)}
                >
                    <Select
                        defaultActiveFirstOption
                        placeholder={FORM_ITEMS.entityId.label}
                        loading={companyEntityLoading}
                    >
                        {companyEntityItems?.docs
                            .filter((item) => item.data().clientCompanyId === acitveCopmanyId)
                            .map((item) => (
                            // FEXME: Can be used .ref.path instead of id
                                <Select.Option key={item.id} value={item.id}>{item.data().name}</Select.Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    {...FORM_ITEMS.reviewer}
                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.reviewer.name)}
                >
                    <Select
                        defaultActiveFirstOption
                        placeholder={FORM_ITEMS.reviewer.label}
                    >
                        {authData.usersInCompany.map((item) => (
                            <Select.Option key={item.uid} value={item.uid}>{item.displayName}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    {...FORM_ITEMS.preparer}
                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.preparer.name)}
                >
                    <Select
                        defaultActiveFirstOption
                        placeholder={FORM_ITEMS.preparer.label}
                    >
                        {authData.usersInCompany.map((item) => (
                            <Select.Option key={item.uid} value={item.uid}>{item.displayName}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    {...FORM_ITEMS.draftNumber}
                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.draftNumber.name)}
                    initialValue={!isItEditMode ? 1 : undefined}
                >
                    <Input placeholder={FORM_ITEMS.draftNumber.label as string}/>
                </Form.Item>

                <Divider/>

                <Form.Item
                    {...FORM_ITEMS.suppFileRcs}
                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.suppFileRcs.name)}
                    rules={[{
                        required: !isItEditMode,
                    }]}
                >
                    <FileDragger
                        customRequest={(options: RcCustomRequestOptions) => draggerCustomRequest(FORM_ITEMS.suppFileRcs, options)}
                    />
                </Form.Item>
                <Form.Item
                    {...FORM_ITEMS.suppFilePriorYearFinStatement}
                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.suppFilePriorYearFinStatement.name)}
                    rules={[{
                        required: !isItEditMode,
                    }]}
                >
                    <FileDragger
                        customRequest={(options: RcCustomRequestOptions) => draggerCustomRequest(FORM_ITEMS.suppFilePriorYearFinStatement, options)}
                    />
                </Form.Item>
                {/* TODO: Will be processed lated */}
                {/*<Form.Item*/}
                {/*    {...FORM_ITEMS.suppFileMapping}*/}
                {/*    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.suppFileMapping.name)}*/}
                {/*>*/}
                {/*    <FileDragger*/}
                {/*        customRequest={(options: RcCustomRequestOptions) => draggerCustomRequest(FORM_ITEMS.suppFileMapping, options)}*/}
                {/*        formats={['xlsx']}*/}
                {/*    />*/}
                {/*</Form.Item>*/}
                {/*<Form.Item*/}
                {/*    {...FORM_ITEMS.suppFileTrialBalance}*/}
                {/*    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.suppFileTrialBalance.name)}*/}
                {/*>*/}
                {/*    <FileDragger*/}
                {/*        customRequest={(options: RcCustomRequestOptions) => draggerCustomRequest(FORM_ITEMS.suppFileTrialBalance, options)}*/}
                {/*        formats={['xlsx']}*/}
                {/*    />*/}
                {/*</Form.Item>*/}
            </Form>
        </Modal>
    )
}
