import { ComercialCompanyReviewStep, ComercialCompanyReviewStepTree } from './comercialCompanySteps.types';

/**
 * suggestedMessageTemplates keys:
 * - first section: block name
 * - second section: element in the block (optional)
 * - third section: problem type
 *
 * TODO: Make a builder of keys for suggestedMessageTemplates
 */
export const COMERCIAL_COMPANY_REVIEW_STEPS: ComercialCompanyReviewStep[] = [
    {
        'key': 'COVER',
        'title': 'Cover page',
        'suggestedMessageTemplates': {},
        'stepExplanationTemplates': [],
    },
    {
        'key': 'COVER:ENTITY_NAME',
        'title': 'Verification of the entity name',
        'relatedPageLabels': ['cover_page'],
        annotationBlockIds: ['cover_page:entity_name'],
        'stepExplanationTemplates': [
            'Does the entity name on the cover page match the one in the RCS document?',
        ],
        'suggestedMessageTemplates': {
            'ENTITY_NAME:NOT_FOUND:RCS_DOCUMENT:COVER': {
                'explanationTemplate': 'The entity name not found in both RCS document and the cover page. Please review whether the correct entity name is displayed on the cover page.',
                'suggestedMessageTemplate': 'The entity name not found in both RCS document and the cover page. Please review whether the correct entity name is displayed on the cover page.',
            },
            'ENTITY_NAME:NOT_FOUND:COVER': {
                'explanationTemplate': 'The entity name is not found on the cover page. Please review whether the correct entity name is displayed on the cover page. The entity name according to the RCS document: <b>{{rcs_entity_name}}</b>.',
                'suggestedMessageTemplate': 'The entity name is not found on the cover page. Please review whether the correct entity name is displayed on the cover page. The entity name according to the RCS document: <b>{{rcs_entity_name}}</b>.',
            },
            'ENTITY_NAME:NOT_FOUND:RCS_DOCUMENT': {
                'explanationTemplate': 'The entity name is not found in the RCS document. Please review whether the correct entity name is displayed on the cover page.',
                'suggestedMessageTemplate': 'The entity name is not found in the RCS document. Please review whether the correct entity name is displayed on the cover page.',
            },
            'ENTITY_NAME:CORRECT:RCS_DOCUMENT:COVER': {
                'explanationTemplate': '',
                'suggestedMessageTemplate': '',
            },
            'ENTITY_NAME:ERROR': {
                'explanationTemplate': 'The entity name on the cover page does not match the RCS document. The entity name according to the RCS document: <b>{{rcs_entity_name}}</b>. The entity name according to the cover page: <b>{{cover_entity_name}}</b>. Please update it accordingly.',
                'suggestedMessageTemplate': 'The entity name on the cover page does not match the RCS document. The entity name according to the RCS document: <b>{{rcs_entity_name}}</b>. The entity name according to the cover page: <b>{{cover_entity_name}}</b>. Please update it accordingly.',
            },
            'ENTITY_NAME:STYLE': {
                'explanationTemplate': 'Formatting discrepancies detected in the entity name. Compare and rectify any differences in spacing, punctuation, and case between the cover page and the RCS document. The entity name as per RCS document: <b>{{rcs_entity_name}}</b>.',
                'suggestedMessageTemplate': 'Formatting discrepancies detected in the entity name. Compare and rectify any differences in spacing, punctuation, and case between the cover page and the RCS document. The entity name as per RCS document: <b>{{rcs_entity_name}}</b>.',
            },
        },
        'checkType': 'AUTOMATIC',
        'parentKey': 'COVER',
    },
    {
        'key': 'COVER:LEGAL_FORM',
        'title': 'Verification of the legal form',
        annotationBlockIds: ['cover_page:legal_form'],
        'stepExplanationTemplates': [
            'Does the legal form on the cover page match the one in the RCS document?',
        ],
        'suggestedMessageTemplates': {
            'LEGAL_FORM:NOT_FOUND:RCS_DOCUMENT:COVER': {
                'explanationTemplate': 'The legal form not found in both RCS document and the cover page. Please review whether the correct legal form is displayed on the cover page.',
                'suggestedMessageTemplate': 'The legal form not found in both RCS document and the cover page. Please review whether the correct legal form is displayed on the cover page.',
            },
            'LEGAL_FORM:NOT_FOUND:COVER': {
                'explanationTemplate': 'The legal form is not found on the cover page. Please review whether the correct legal form is displayed on the cover page. The legal form according to the RCS document: <b>{{rcs_legal_form}}</b>.',
                'suggestedMessageTemplate': 'The legal form is not found on the cover page. Please review whether the correct legal form is displayed on the cover page. The legal form according to the RCS document: <b>{{rcs_legal_form}}</b>.',
            },
            'LEGAL_FORM:NOT_FOUND:RCS_DOCUMENT': {
                'explanationTemplate': 'The legal form is not found in the RCS document. Please review whether the correct legal form is displayed on the cover page.',
                'suggestedMessageTemplate': 'The legal form is not found in the RCS document. Please review whether the correct legal form is displayed on the cover page.',
            },
            'LEGAL_FORM:CORRECT:RCS_DOCUMENT:COVER': {
                'explanationTemplate': 'The legal form on the cover page matches the one in the RCS document.',
                'suggestedMessageTemplate': 'The legal form on the cover page matches the one in the RCS document.',
            },
            'LEGAL_FORM:ERROR': {
                'explanationTemplate': 'The legal form on the cover page does not match the RCS document. The legal form according to the RCS document: <b>{{rcs_legal_form}}</b>. The legal form according to the cover page: <b>{{cover_legal_form}}</b>. Please update it accordingly.',
                'suggestedMessageTemplate': 'The legal form on the cover page does not match the RCS document. The legal form according to the RCS document: <b>{{rcs_legal_form}}</b>. The legal form according to the cover page: <b>{{cover_legal_form}}</b>. Please update it accordingly.',
            },
            'LEGAL_FORM:STYLE': {
                'explanationTemplate': 'Formatting discrepancies detected in the legal form. Compare and rectify any differences in spacing, punctuation, and case between the cover page and the RCS document. The legal form as per RCS document: <b>{{rcs_legal_form}}</b>.',
                'suggestedMessageTemplate': 'Formatting discrepancies detected in the legal form. Compare and rectify any differences in spacing, punctuation, and case between the cover page and the RCS document. The legal form as per RCS document: <b>{{rcs_legal_form}}</b>.',
            },
        },
        'checkType': 'AUTOMATIC',
        'parentKey': 'COVER',
    },
    {
        'key': 'COVER:RCS_NUMBER',
        'title': 'Verification of the RCS number',
        annotationBlockIds: ['cover_page:rcs_number'],
        'stepExplanationTemplates': [
            'Does the RCS number on the cover page match the one in the RCS document?',
        ],
        'suggestedMessageTemplates': {
            'RCS_NUMBER:NOT_FOUND:RCS_DOCUMENT:COVER': {
                'explanationTemplate': 'The RCS number not found in both RCS document and the cover page. Please review whether the correct RCS number is displayed on the cover page.',
                'suggestedMessageTemplate': 'The RCS number not found in both RCS document and the cover page. Please review whether the correct RCS number is displayed on the cover page.',
            },
            'RCS_NUMBER:NOT_FOUND:COVER': {
                'explanationTemplate': 'The RCS number is not found on the cover page. Please review whether the correct RCS number is displayed on the cover page. The RCS number according to the RCS document: <b>{{rcs_rcs_number}}</b>.',
                'suggestedMessageTemplate': 'The RCS number is not found on the cover page. Please review whether the correct RCS number is displayed on the cover page. The RCS number according to the RCS document: <b>{{rcs_rcs_number}}</b>.',
            },
            'RCS_NUMBER:NOT_FOUND:RCS_DOCUMENT': {
                'explanationTemplate': 'The RCS number is not found in the RCS document. Please review whether the correct RCS number is displayed on the cover page.',
                'suggestedMessageTemplate': 'The RCS number is not found in the RCS document. Please review whether the correct RCS number is displayed on the cover page.',
            },
            'RCS_NUMBER:CORRECT:RCS_DOCUMENT:COVER': {
                'explanationTemplate': 'The RCS number on the cover page matches the one in the RCS document.',
                'suggestedMessageTemplate': 'The RCS number on the cover page matches the one in the RCS document.',
            },
            'RCS_NUMBER:ERROR': {
                'explanationTemplate': 'The RCS number on the cover page does not match the RCS document. The RCS number according to the RCS document: <b>{{rcs_rcs_number}}</b>. The RCS number according to the cover page: <b>{{cover_rcs_number}}</b>. Please update it accordingly.',
                'suggestedMessageTemplate': 'The RCS number on the cover page does not match the RCS document. The RCS number according to the RCS document: <b>{{rcs_rcs_number}}</b>. The RCS number according to the cover page: <b>{{cover_rcs_number}}</b>. Please update it accordingly.',
            },
            'RCS_NUMBER:STYLE': {
                'explanationTemplate': 'Formatting discrepancies detected in the RCS number. Compare and rectify any differences in spacing, punctuation, and case between the cover page and the RCS document. The RCS number as per RCS document: <b>{{rcs_rcs_number}}</b>',
                'suggestedMessageTemplate': 'Formatting discrepancies detected in the RCS number. Compare and rectify any differences in spacing, punctuation, and case between the cover page and the RCS document. The RCS number as per RCS document: <b>{{rcs_rcs_number}}</b>.',
            },
        },
        'checkType': 'AUTOMATIC',
        'parentKey': 'COVER',
    },
    {
        'key': 'COVER:DATE',
        'title': 'Verification of the date',
        annotationBlockIds: ['cover_page:date'],
        'stepExplanationTemplates': ['Is the period-end date on the cover page correct?'],
        'suggestedMessageTemplates': {
            'DATE:INCONSISTENT': {
                'explanationTemplate': 'Please review the date. It is inconsistent with the date provided during the upload of the file.',
                'suggestedMessageTemplate': 'Please review the date. It is inconsistent with the date provided during the upload of the file.',
            },
            'DATE:COVER_NOT_FOUND': {
                'explanationTemplate': 'The date on the cover page has not been identified. Please review it manually.',
                'suggestedMessageTemplate': 'The date on the cover page has not been identified. Please review it manually.',
            },
            'DATE:CONSISTENT': {
                'explanationTemplate': '',
                'suggestedMessageTemplate': '',
            },
        },
        'checkType': 'AUTOMATIC',
        'parentKey': 'COVER',
    },
    {
        'key': 'COVER:REGISTERED_ADDRESS',
        annotationBlockIds: ['cover_page:registered_adress'],
        'title': 'Verification of the registered address',
        'stepExplanationTemplates': [],
        'suggestedMessageTemplates': {
            'COVER:ADDRESS': {
                'explanationTemplate': 'Please review the registered address on the cover page manually.',
                'suggestedMessageTemplate': 'Please review the registered address on the cover page manually.',
            },
            'COVER:ADDRESS:CORRECT': {
                'explanationTemplate': '',
                'suggestedMessageTemplate': '',
            },
        },
        subChecks: [
            {
                'key': 'COVER:REGISTERED_ADDRESS_VER',
                'title': 'Registered address verified',
                'explanation': 'Registered address on the cover page should be aligned with the RCS document.',
            },
        ],
        'checkType': 'MANUAL',
        'parentKey': 'COVER',
    },
    {
        'key': 'ECDF',
        'title': 'eCDF',
        'relatedPageLabels': ['ecdf'],
        'suggestedMessageTemplates': {},
        'stepExplanationTemplates': [],
    },
    {
        'key': 'ECDF:PREVIOUS_YEAR_COLUMN_BALANCES_CHECK',
        'title': 'Verification of eCDF previous year column balances',
        'stepExplanationTemplates': [],
        'suggestedMessageTemplates': {
            'CONSISTENCY:YEAR:ONE_FIELD_NOT_CORRECT_PY': {
                'explanationTemplate': 'The field <b>{{field}}</b> is not aligned with the last year\'s financial statements.',
                'suggestedMessageTemplate': 'The field <b>{{field}}</b> is not aligned with the last year\'s financial statements.',
            },
            'CONSISTENCY:YEAR:MULTIPLE_FIELDS_NOT_CORRECT_PY': {
                'explanationTemplate': 'Following fields: <b>{{multiple_fields}}</b> are not aligned with the last year\'s financial statements.',
                'suggestedMessageTemplate': 'Following fields: <b>{{multiple_fields}}</b> are not aligned with the last year\'s financial statements.',
            },
            'CONSISTENCY:YEAR:CORRECT_PY': {
                'explanationTemplate': '',
                'suggestedMessageTemplate': '',
            },
        },
        'checkType': 'AUTOMATIC',
        'parentKey': 'ECDF',
    },
    {
        'key': 'ECDF:INTERNAL_VALIDATION_OF_BALANCES',
        'title': 'Verification of eCDF balances',
        'stepExplanationTemplates': [
            'This control procedure ensures that all fields in the Balance Sheet and Profit and Loss Account are correct as per eCDF validation rules.',
        ],
        'suggestedMessageTemplates': {
            'ECDF:FIELD:WRONG_VALUE': {
                'explanationTemplate': 'The value in field <b>{{field}}</b> cannot be lower than <b>0</b>.',
                'suggestedMessageTemplate': 'The value in field <b>{{field}}</b> cannot be lower than <b>0</b>.',
            },
            'ECDF:FIELD:MANDATORY': {
                'explanationTemplate': 'Field <b>{{field}}</b> is mandatory if one of the fields <b>{{fields_to_compare}}</b> is filled.',
                'suggestedMessageTemplate': 'Field <b>{{field}}</b> is mandatory if one of the fields <b>{{fields_to_compare}}</b> is filled.',
            },
            'ECDF:FIELD:TOTAL_NOT_CORRECT': {
                'explanationTemplate': 'The total in the field <b>{{field}}</b> is not correct. The total should be <b>{{correct_total}}</b>.',
                'suggestedMessageTemplate': 'The total in the field <b>{{field}}</b> is not correct. The total should be <b>{{correct_total}}</b>.',
            },
            'ECDF:FIELD:TOTAL_NOT_LOWER': {
                'explanationTemplate': 'The total in the field <b>{{field}}</b> is not correct. The total cannot be lower than <b>0</b>.',
                'suggestedMessageTemplate': 'The total in the field <b>{{field}}</b> is not correct. The total cannot be lower than <b>0</b>.',
            },
            'ECDF:FIELD:EQUAL': {
                'explanationTemplate': 'Field <b>{{field}}</b> should be equal to the sum of fields <b>{{fields_to_compare}}</b>.',
                'suggestedMessageTemplate': 'Field <b>{{field}}</b> should be equal to the sum of fields <b>{{fields_to_compare}}</b>.',
            },
            'ECDF:FIELD:EMPTY_201': {
                'explanationTemplate': 'Field <b>201</b> is empty, this field is mandatory.',
                'suggestedMessageTemplate': 'Field <b>201</b> is empty, this field is mandatory',
            },
            'ECDF:FIELD:EMPTY_202': {
                'explanationTemplate': 'Field <b>202</b> is empty, this field is mandatory.',
                'suggestedMessageTemplate': 'Field <b>202</b> is empty, this field is mandatory.',
            },
            'ECDF:FIELD:TOTAL_201': {
                'explanationTemplate': 'The total in the field <b>201</b> is not correct. Field <b>201</b> should be equal to the sum of fields <b>101</b>, <b>107</b>, <b>109</b>, <b>151</b>, and <b>199</b>.',
                'suggestedMessageTemplate': 'The total in the field <b>201</b> is not correct. Field <b>201</b> should be equal to the sum of fields <b>101</b>, <b>107</b>, <b>109</b>, <b>151</b>, and <b>199</b>.',
            },
            'ECDF:FIELD:TOTAL_202': {
                'explanationTemplate': 'The total in the field <b>202</b> is not correct. Field <b>202</b> should be equal to the sum of fields <b>102</b>, <b>108</b>, <b>110</b>, <b>152</b>, and <b>200</b>.',
                'suggestedMessageTemplate': 'The total in the field <b>202</b> is not correct. Field <b>202</b> should be equal to the sum of fields <b>102</b>, <b>108</b>, <b>110</b>, <b>152</b>, and <b>200</b>.',
            },
            'ECDF:FIELD:ACC_EQ_201': {
                'explanationTemplate': 'The total assets for the current year do not match the total capital, reserves, and liabilities. The value in field <b>201</b> should equal the value in field <b>405</b>.',
                'suggestedMessageTemplate': 'The total assets for the current year do not match the total capital, reserves, and liabilities. The value in field <b>201</b> should equal the value in field <b>405</b>.',
            },
            'ECDF:FIELD:ACC_EQ_202': {
                'explanationTemplate': 'The total assets for the current year do not match the total capital, reserves, and liabilities. The value in field <b>202</b> should equal the value in field <b>406</b>.',
                'suggestedMessageTemplate': 'The total assets for the current year do not match the total capital, reserves, and liabilities. The value in field <b>202</b> should equal the value in field <b>406</b>.',
            },
            'ECDF:FIELD:BS_PNL_EQ': {
                'explanationTemplate': 'Field <b>{{field}}</b> should be equal to field <b>{{fields_to_compare}}</b>.',
                'suggestedMessageTemplate': 'Field <b>{{field}}</b> should be equal to field <b>{{fields_to_compare}}</b>.',
            },
            'ECDF:FIELD:MISSING': {
                'explanationTemplate': 'The eCDF section is incomplete and lacks field(s) <b>{{field}}</b>. Ensure that all necessary fields are included.',
                'suggestedMessageTemplate': 'The eCDF section is incomplete and lacks field(s) <b>{{field}}</b>. Ensure that all necessary fields are included.',
            },
            'ECDF:FIELD:CORRECT': {
                'explanationTemplate': '',
                'suggestedMessageTemplate': '',
            },
        },
        subChecks: [
            {
                'key': 'ECDF:FIELD:MISSING_SUB',
                'title': 'Verification that all eCDF fields are included',
                'explanation': 'This verification step ensures that every field required by eCDF is included in the form.',
            },
            {
                'key': 'ECDF:FIELD:TOTAL_NOT_LOWER_SUB',
                'title': 'Verification that fields requiring values above zero meet this criterion',
                'explanation': '',
            },
            {
                'key': 'ECDF:FIELD:MANDATORY_SUB',
                'title': 'Verification that mandatory fields are completed',
                'explanation': 'This verification step ensures that every mandatory field in the eCDF form is filled in with a value.',
            },
            {
                'key': 'ECDF:FIELD:TOTAL_NOT_CORRECT_SUB',
                'title': 'Verification of the mathematical accuracy of total and sub-total fields',
                'explanation': '',
            },
            {
                'key': 'ECDF:FIELD:BS_PNL_EQ_SUB',
                'title': 'Verification that the balance sheet and profit and loss statement are balanced',
                'explanation': 'This verification step ensures that total assets are equal to the sum of total capital, reserves, and liabilities. Any discrepancy between these figures may indicate an error or omission that needs to be addressed before finalizing the financial statements.',
            },
        ],
        'checkType': 'AUTOMATIC',
        'parentKey': 'ECDF',
    },
    {
        key: 'ECDF:MAPPING',
        parentKey: 'ECDF',
        title: 'Verification of accounts mapping',
        stepExplanationTemplates: [],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'ECDF:MAPPING_CHECK',
                'title': 'Mapping verified for the accuracy and consistency',
                'explanation': 'This control procedure ensures that the account mapping for each caption is accurate and consistent with the prior year\'s financial statements.',
            },
        ],
    },
    {
        'key': 'ECDF:COMPARISON_OF_ECDF_BALANCES_WITH_NOTES',
        'title': 'Verification of eCDF balances against the notes to the financial statements',
        'stepExplanationTemplates': [],
        'suggestedMessageTemplates': {
            'ECDF:FIELD:ONE_NOTE_ECDF_DISCR': {
                'explanationTemplate': 'The field <b>{{field}}</b> is not in line with the note <b>{{note_number}}</b>.',
                'suggestedMessageTemplate': 'The field <b>{{field}}</b> is not in line with the note <b>{{note_number}}</b>.',
            },
            'ECDF:FIELD:MULTIPLE_NOTE_ECDF_DISCR': {
                'explanationTemplate': 'The following fields <b>{{multiple_fields}}</b> are not in line with the note <b>{{note_number}}</b>, respectively.',
                'suggestedMessageTemplate': 'The following fields <b>{{multiple_fields}}</b> are not in line with the note <b>{{note_number}}</b>, respectively.',
            },
            'ECDF:FIELD:NOTE_ECDF_CORRECT': {
                'explanationTemplate': '',
                'suggestedMessageTemplate': '',
            },
        },
        subChecks: [
            {
                'key': 'COMPARISON_OF_ECDF_BALANCES_WITH_NOTES_SUB',
                'title': 'Consistency between eCDF and notes balances verified',
                'explanation': 'This control procedure ensures that balances in eCDF are consistent with corresponding tables in the notes.',
            },
        ],
        'checkType': 'AUTOMATIC',
        'parentKey': 'ECDF',
    },
    {
        'key': 'ECDF:RCS_NUMBER_CHECK',
        'title': 'Verification of the RCS number in the eCDF section',
        'stepExplanationTemplates': [
            'Does the RCS number in the eCDF section match the one in the RCS document?',
        ],
        'suggestedMessageTemplates': {
            'ECDF:RCS_NUMBER:NOT_FOUND_IN_RCS': {
                'explanationTemplate': 'The RCS number is not found in the RCS document. Please review whether the correct RCS number is displayed in the eCDF section.',
                'suggestedMessageTemplate': 'The RCS number is not found in the RCS document. Please review whether the correct RCS number is displayed in the eCDF section.',
            },
            'ECDF:RCS_NUMBER:NOT_FOUND_ON_ECDF': {
                'explanationTemplate': 'The RCS number is not found in the eCDF section, on page(s) <b>{{pageCount}}</b>. Please review whether the correct RCS number is displayed in the eCDF section. The RCS number according to the RCS document: <b>{{rcs_rcs_number}}</b>.',
                'suggestedMessageTemplate': 'The RCS number is not found in the eCDF section, on page(s) <b>{{pageCount}}</b>. Please review whether the correct RCS number is displayed in the eCDF section. The The RCS number according to the RCS document: <b>{{rcs_rcs_number}}</b>.',
            },
            'ECDF:RCS_NUMBER:NOT_FOUND_ON_ECDF_RCS': {
                'explanationTemplate': 'The RCS number not found in both RCS document and the eCDF section, on page(s) <b>{{pageCount}}</b>. Please review whether the correct RCS number is displayed in the eCDF section',
                'suggestedMessageTemplate': 'The RCS number not found in both RCS document and the eCDF section, on page(s) <b>{{pageCount}}</b>. Please review whether the correct RCS number is displayed in the eCDF section',
            },
            'ECDF:RCS_NUMBER:CORRECT': {
                'explanationTemplate': '',
                'suggestedMessageTemplate': '',
            },
            'ECDF:RCS_NUMBER:INCORRECT': {
                'explanationTemplate': 'The RCS numbers in the eCDF section do not match those in the RCS document. Specifically, on page(s) <b>{{pageCount}}</b>, the incorrect RCS number(s) listed in the eCDF section are <b>{{ecdf_rcs_number}}</b>, respectively. According to the RCS document, the correct RCS number is <b>{{rcs_rcs_number}}</b>. Please update accordingly.',
                'suggestedMessageTemplate': 'The RCS numbers in the eCDF section do not match those in the RCS document. Specifically, on page(s) <b>{{pageCount}}</b>, the incorrect RCS number(s) listed in the eCDF section are <b>{{ecdf_rcs_number}}</b>, respectively. According to the RCS document, the correct RCS number is <b>{{rcs_rcs_number}}</b>. Please update accordingly.',
            },
            'ECDF:RCS_NUMBER:SYSTEM_ECDF_PAGES_NOT_FOUND': {
                'explanationTemplate': 'The RCS number in the eCDF has not been reviewed. The eCDF pages were not recognized',
                'suggestedMessageTemplate': 'The RCS number in the eCDF has not been reviewed. The eCDF pages were not recognize',
            },
        },
        'checkType': 'AUTOMATIC',
        'parentKey': 'ECDF',
    },
    {
        'key': 'NOTES',
        'relatedPageLabels': ['notes'],
        'title': 'Notes to the financial statements',
        'suggestedMessageTemplates': {},
        'stepExplanationTemplates': [],
    },
    {
        key: 'NOTES:GENERAL_INFORMATION',
        parentKey: 'NOTES',
        title: 'General information',
        stepExplanationTemplates: ['Does the general information note include the following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:GENERAL_INFORMATION:COMPANY_NAME',
                'title': 'Company\'s name',
                'explanation': 'It is considered best practice to disclose the company\'s name in the general information note. The name should align with the one in the RCS document.',
            },
            {
                'key': 'NOTES:GENERAL_INFORMATION:INC_DATE',
                'title': 'Company\'s incorporation date',
                'explanation': 'It is considered best practice to disclose the company\'s incorporation date in the general information note. The date should align with the one in the RCS document.',
            },
            {
                'key': 'NOTES:GENERAL_INFORMATION:LEGAL_FORM',
                'title': 'Legal form',
                'explanation': 'It is considered best practice to disclose the legal form in the general information note. The legal form name should align with the one in the RCS document.',
            },
            {
                'key': 'NOTES:GENERAL_INFORMATION:REGISTERED_OFFICE',
                'title': 'Registered office',
                'explanation': 'It is considered best practice to disclose the registered address in the general information note. The registered address should align with the one in the RCS document.',
            },
            {
                'key': 'NOTES:GENERAL_INFORMATION:DURATION',
                'title': 'Duration',
                'explanation': 'It is considered best practice to disclose the duration of the company\'s establishment in the general information note. This duration should match the one stated in the RCS document. If the company has been established for an unlimited duration, this should be specified.',
            },
            {
                'key': 'NOTES:GENERAL_INFORMATION:TRADE_REGISTER_NUMBER',
                'title': 'RCS number',
                'explanation': 'It is considered best practice to disclose the RCS number in the general information note. The RCS number should align with the one in the RCS document.',
            },
            {
                'key': 'NOTES:GENERAL_INFORMATION:FINANCIAL_YEAR_DATES',
                'title': 'Financial year dates',
                'explanation': 'It is considered best practice to disclose the beginning and end dates of the financial year in the general information note. These dates should align with those in the RCS document.',
            },
            {
                'key': 'NOTES:GENERAL_INFORMATION:PURPOSE',
                'title': 'Company\'s purpose',
                'explanation': 'It is considered best practice to disclose the company\'s purpose in the general information note. This purpose should align with the Articles of Incorporation.',
            },
            {
                'key': 'NOTES:GENERAL_INFORMATION:DECISIONS',
                'title': 'Significant events',
                'explanation': 'It is considered best practice to disclose significant events of the year that impact the annual accounts, to improve understanding of the report.',
            },
        ],
    },
    {
        key: 'NOTES:ACCOUNTING_POLICIES',
        parentKey: 'NOTES',
        title: 'Accounting policies',
        stepExplanationTemplates: ['Does the accounting policy note include the following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:ACCOUNTING_POLICIES:POLICIES',
                'title': 'Disclosure of accounting policies and valuation rules',
                'explanation': 'Disclosure of the accounting policies and valuation rules applied to the items in the annual accounts (including principles, bases, conventions, rules and specific practices)',
            },
            {
                'key': 'NOTES:ACCOUNTING_POLICIES:CONFORMITY',
                'title': 'Statement on the conformity',
                'explanation': 'Disclosure of statement on the conformity of the accounting methods with the going concern principle.',
            },
            {
                'key': 'NOTES:ACCOUNTING_POLICIES:CURRENCY',
                'title': 'Exchange rates used',
                'explanation': 'Mention of the currency in which the accounting records are maintained and the annual accounts are expressed.',
            },
        ],
    },
    {
        'key': 'NOTES:PREVIOUS_YEAR_COLUMN_BALANCES_CHECK',
        'title': 'Verification of previous year column balances in the notes to financial statements',
        'stepExplanationTemplates': [],
        'suggestedMessageTemplates': {
            'CONSISTENCY:YEAR:ONE_FIELD_NOT_CORRECT': {
                'explanationTemplate': 'In the note <b>{{note_number}}</b>, prior year column balances are not in line with the last year\'s financial statements.',
                'suggestedMessageTemplate': 'In the note <b>{{note_number}}</b>, prior year column balances are not in line with the last year\'s financial statements.',
            },
            'CONSISTENCY:YEAR:MULTIPLE_FIELDS_NOT_CORRECT': {
                'explanationTemplate': 'In the following notes <b>{{notes_numbers}}</b>, prior year column balances are not in line with the last year\'s financial statements.',
                'suggestedMessageTemplate': 'In the following notes <b>{{notes_numbers}}</b>, prior year column balances are not in line with the last year\'s financial statements.',
            },
            'CONSISTENCY:YEAR:CORRECT': {
                'explanationTemplate': '',
                'suggestedMessageTemplate': '',
            },
        },
        subChecks: [
            {
                'key': 'NOTES:PREVIOUS_YEAR_COLUMN_BALANCES_CHECK_SUB',
                'title': 'Previous year column balances verified',
                'explanation': 'This control procedure verifies that the \'Previous Year\' column in the notes to financial statements matches the \'Current Year\' column in the notes of the previous year.',
            },
        ],
        'checkType': 'AUTOMATIC',
        'parentKey': 'NOTES',
    },
    {
        key: 'NOTES:FORMATION_EXPENSES',
        parentKey: 'NOTES',
        title: 'Formation expenses',
        stepExplanationTemplates: ['Does formation expenses note include following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:FORMATION_EXPENSES:VALUE_ADJUSTMENTS_DISCLOSURE',
                'title': 'Disclosure of value adjustments',
                'explanation': 'Value adjustments should be disclosed in the formation expenses note.',
            },
            {
                'key': 'NOTES:FORMATION_EXPENSES:DISCLOSURE_OF_MOVEMENTS',
                'title': 'Disclosure of movements in the formation expenses',
                'explanation': 'Unless shown in the balance sheet, disclosure of movements in the formation expenses, i.e. \n- purchase price or production cost;\n- additions, disposals and transfers during the financial year;\n- cumulative value adjustments at the balance sheet date;\n- rectifications made during the financial year to the value adjustments of previous financial years. Value adjustments shall be shown in the notes to the accounts.',
            },
            {
                'key': 'NOTES:FORMATION_EXPENSES:COMMENTS',
                'title': 'Disclosure of comments on the formation expenses',
                'explanation': 'Items included under "Formation expenses" should be commented on in the formation expenses note',
            },
            {
                'key': 'NOTES:FORMATION_EXPENSES:MAPPING',
                'title': 'Verify the mapping behind numbers in table disclosures',
                'explanation': 'Ensure that the figures presented in table disclosures are accurately mapped to the underlying accounts. This verification is essential to confirm the consistency and reliability of the reported values.',
            },
        ],
    },
    {
        key: 'NOTES:INTANGIBLE_ASSETS',
        parentKey: 'NOTES',
        title: 'Intangible assets',
        stepExplanationTemplates: ['Does intangible assets note include following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:INTANGIBLE_ASSETS:VALUE_ADJUSTMENTS_DISCLOSURE',
                'title': 'Disclosure of value adjustments',
                'explanation': 'Unless shown separately in the profit and loss account, value adjustments should be disclosed in the intangible assets note.',
            },
            {
                'key': 'NOTES:INTANGIBLE_ASSETS:DISCLOSURE_OF_MOVEMENTS',
                'title': 'Disclosure of movements in the intangible assets',
                'explanation': 'Unless shown in the balance sheet, disclosure of movements in the intangible asset items, i.e. for each fixed asset item:\n- purchase price or production cost;\n- additions, disposals and transfers during the financial year;\n- cumulative value adjustments at the balance sheet date;\n- rectifications made during the financial year to the value adjustments of previous financial years. Value adjustments shall be shown in the notes to the accounts.',
            },
            {
                'key': 'NOTES:INTANGIBLE_ASSETS:CAPITALIZATION_OF_INTEREST',
                'title': 'Disclosure of capitalization of interest',
                'explanation': 'The capitalisation of interest on capital borrowed to finance the production of intangible fixed assets must be disclosed in the notes to the accounts.',
            },
            {
                'key': 'NOTES:INTANGIBLE_ASSETS:EXCEPTIONAL_VALUE_ADJUSTMENTS',
                'title': 'Disclosure of exceptional value adjustments',
                'explanation': 'If intangible assets are subject to value adjustment solely for taxation purposes, the amount of the adjustments and the reasons for making them shall be indicated in the notes.',
            },
            {
                'key': 'NOTES:INTANGIBLE_ASSETS:COSTS_OF_DEVELOPMENT',
                'title': 'Disclosure of costs of development',
                'explanation': 'If such cost has been incurred, disclosure of the comments on the amounts entered under the \'Costs of development\' item should be disclosed in the intangible assets note.',
            },
            {
                'key': 'NOTES:INTANGIBLE_ASSETS:USEFUL_ECONOMIC_LIFE',
                'title': 'Disclosure of useful economic life',
                'explanation': 'The useful economic life used to amortize the intangible assets should be disclosed. If the useful economic life of the goodwill or the cost of development cannot be reliably estimated, they are amortized on a period not exceeding 10 years. Explanation of such circumstances should be included in the notes.',
            },
            {
                'key': 'NOTES:INTANGIBLE_ASSETS:MAPPING',
                'title': 'Verify the mapping behind numbers in table disclosures',
                'explanation': 'Ensure that the figures presented in table disclosures are accurately mapped to the underlying accounts. This verification is essential to confirm the consistency and reliability of the reported values.',
            },
        ],
    },
    {
        key: 'NOTES:TANGIBLE_ASSETS',
        parentKey: 'NOTES',
        title: 'Tangible assets',
        stepExplanationTemplates: ['Does tangible assets note include following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:TANGIBLE_ASSETS:VALUE_ADJUSTMENTS_DISCLOSURE',
                'title': 'Disclosure of value adjustments',
                'explanation': 'Unless shown separately in the profit and loss account, value adjustments should be disclosed in the tangible assets note.',
            },
            {
                'key': 'NOTES:TANGIBLE_ASSETS:DISCLOSURE_OF_MOVEMENTS',
                'title': 'Disclosure of movements in the tangible assets',
                'explanation': 'Unless shown in the balance sheet, disclosure of movements in the tangible asset items, i.e. for each fixed asset item:\n- purchase price or production cost;\n- additions, disposals and transfers during the financial year;\n- cumulative value adjustments at the balance sheet date;\n- rectifications made during the financial year to the value adjustments of previous financial years. Value adjustments shall be shown in the notes to the accounts.',
            },
            {
                'key': 'NOTES:TANGIBLE_ASSETS:CAPITALIZATION_OF_INTEREST',
                'title': 'Disclosure of capitalization of interest',
                'explanation': 'The capitalisation of interest on capital borrowed to finance the production of tangible assets must be disclosed in the notes to the accounts.',
            },
            {
                'key': 'NOTES:TANGIBLE_ASSETS:EXCEPTIONAL_VALUE_ADJUSTMENTS',
                'title': 'Disclosure of exceptional value adjustments',
                'explanation': 'If tangible assets are subject to value adjustment solely for taxation purposes, the amount of the adjustments and the reasons for making them shall be indicated in the notes',
            },
            {
                'key': 'NOTES:TANGIBLE_ASSETS:MAPPING',
                'title': 'Verify the mapping behind numbers in table disclosures',
                'explanation': 'Ensure that the figures presented in table disclosures are accurately mapped to the underlying accounts. This verification is essential to confirm the consistency and reliability of the reported values.',
            },
        ],
    },
    {
        key: 'NOTES:FINANCIAL_ASSETS',
        parentKey: 'NOTES',
        title: 'Financial assets',
        stepExplanationTemplates: ['Does the financial asset note include the following details for each undertaking in which the company holds at least 20% of the capital?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:FINANCIAL_ASSETS:ADDRESS',
                'title': 'Name and registered office',
                'explanation': 'This information may be omitted where for the purposes of true and fair view it is immaterial.',
            },
            {
                'key': 'NOTES:FINANCIAL_ASSETS:LEGAL_FORM',
                'title': 'The legal form',
                'explanation': 'This information may be omitted where for the purposes of true and fair view it is immaterial.',
            },
            {
                'key': 'NOTES:FINANCIAL_ASSETS:CAPITAL_HELD',
                'title': 'The proportion of capital held',
                'explanation': 'This information may be omitted where for the purposes of true and fair view it is immaterial.',
            },
            {
                'key': 'NOTES:FINANCIAL_ASSETS:CAPITAL_RESERVES',
                'title': 'The capital reserves and profit or loss for the latest financial year',
                'explanation': 'This information may be omitted where for the purposes of true and fair view it is immaterial.',
            },
            {
                'key': 'NOTES:FINANCIAL_ASSETS:TRANSFERS',
                'title': 'The additions, disposals, and transfers during the financial year',
                'explanation': 'This information may be omitted if already disclosed in the balance sheet.',
            },
            {
                'key': 'NOTES:FINANCIAL_ASSETS:VALUE_ADJUSTMENTS',
                'title': 'Cumulative value adjustments',
                'explanation': 'This information may be omitted if already disclosed in the balance sheet.',
            },
            {
                'key': 'NOTES:FINANCIAL_ASSETS:MAPPING',
                'title': 'Verify the mapping behind numbers in table disclosures',
                'explanation': 'Ensure that the figures presented in table disclosures are accurately mapped to the underlying accounts. This verification is essential to confirm the consistency and reliability of the reported values.',
            },
        ],
    },
    {
        key: 'NOTES:CURRENT_ASSETS',
        parentKey: 'NOTES',
        title: 'Current assets',
        stepExplanationTemplates: ['Does current assets note include following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:CURRENT_ASSETS:TABLE',
                'title': 'Disclosure of the current assets',
                'explanation': 'Current assets should be disclosed in the table or written format.',
            },
            {
                'key': 'NOTES:CURRENT_ASSETS:MAPPING',
                'title': 'Verify the mapping behind numbers in table disclosures',
                'explanation': 'Ensure that the figures presented in table disclosures are accurately mapped to the underlying accounts. This verification is essential to confirm the consistency and reliability of the reported values.',
            },
        ],
    },
    {
        key: 'NOTES:CAPITAL_AND_RESERVES',
        parentKey: 'NOTES',
        title: 'Capital and reserves',
        stepExplanationTemplates: ['Does capital and reserves note include following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:CAPITAL_AND_RESERVES:SUBSCRIBED_CAPITAL',
                'title': 'Subscribed capital',
                'explanation': '',
            },
            {
                'key': 'NOTES:CAPITAL_AND_RESERVES:UNCALLED_CAPITAL',
                'title': 'Uncalled capital ',
                'explanation': '',
            },
            {
                'key': 'NOTES:CAPITAL_AND_RESERVES:CALLED_NOT_PAID_CAPITAL',
                'title': 'Called but not paid capital',
                'explanation': '',
            },
            {
                'key': 'NOTES:CAPITAL_AND_RESERVES:CAPITAL_CHANGES',
                'title': 'Capital movements',
                'explanation': 'Mention of any increase / decrease / conversion in capital.',
            },
            {
                'key': 'NOTES:CAPITAL_AND_RESERVES:SHARES_DISCLOSURE',
                'title': 'Number and nominal value of each class of shares',
                'explanation': 'Disclosure of the number and the nominal value or, in the absence of a nominal value, the accounting par value of each class of shares.',
            },
            {
                'key': 'NOTES:CAPITAL_AND_RESERVES:MAPPING',
                'title': 'Verify the mapping behind numbers in table disclosures',
                'explanation': 'Ensure that the figures presented in table disclosures are accurately mapped to the underlying accounts. This verification is essential to confirm the consistency and reliability of the reported values.',
            },
        ],
    },
    {
        key: 'NOTES:CREDITORS',
        parentKey: 'NOTES',
        title: 'Creditors',
        stepExplanationTemplates: ['Does creditors note include following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:CREDITORS:AMOUNTS_OWED',
                'title': 'Disclosure of the amounts owed by the undertaking becoming due and payable after more than five years',
                'explanation': 'Disclosure of the amounts owed by the undertaking becoming due and payable after more than five years.',
            },
            {
                'key': 'NOTES:CREDITORS:MAPPING',
                'title': 'Verify the mapping behind numbers in table disclosures',
                'explanation': 'Ensure that the figures presented in table disclosures are accurately mapped to the underlying accounts. This verification is essential to confirm the consistency and reliability of the reported values.',
            },
        ],
    },
    {
        key: 'NOTES:OFF_BALANCE_SHEET',
        parentKey: 'NOTES',
        title: 'Off-balance sheet commitments',
        stepExplanationTemplates: ['Does the off-balance sheet commitments note include following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:OFF_BALANCE_SHEET_SUB1',
                'title': 'Disclosure of off-balance sheet financial commitments',
                'explanation': 'Indicate any financial commitments (given and received) not included in the balance sheet, especially if they are significant for assessing the Company’s financial position. Additionally, describe the nature and commercial objective of those operations.',
            },
        ],
    },
    {
        key: 'NOTES:POST_BALANCE_SHEET',
        parentKey: 'NOTES',
        title: 'Post balance sheet events',
        stepExplanationTemplates: ['Does the post balance sheet events note include following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:POST_BALANCE_SHEET_SUB1',
                'title': 'Disclosure of post balance sheet events',
                'explanation': 'Disclose of the nature and financial impact of the material post balance sheet events that are not reflected in the accounts',
            },
        ],
    },
    {
        'key': 'ENTIRE_REPORT',
        'title': 'Entire report',
        'suggestedMessageTemplates': {},
        'stepExplanationTemplates': [],
    },
    {
        'key': 'NOTES:TOTALS_CHECK',
        'title': 'Recalculate the total lines in each table to confirm mathematical accuracy',
        'stepExplanationTemplates': ['Are the totals in each table correct?'],
        'suggestedMessageTemplates': {
            'NOTES:ONE_TOTAL_LINE_INCORRECT': {
                'explanationTemplate': 'The total line in the note <b>{{note_number}}</b>, in column <b>{{period}}</b> is incorrect.',
                'suggestedMessageTemplate': 'The total line in the note <b>{{note_number}}</b>, in column <b>{{period}}</b> is incorrect.',
            },
            'NOTES:MULTIPLE_TOTAL_LINE_INCORRECT': {
                'explanationTemplate': 'The total lines in the following notes <b>{{note_number}}</b>, in column <b>{{period}}</b> are incorrect.',
                'suggestedMessageTemplate': 'The total lines in the following notes <b>{{note_number}}</b>, in column <b>{{period}}</b> are incorrect.',
            },
            'NOTES:MULTIPLE_TOTAL_LINE_CORRECT': {
                'explanationTemplate': '',
                'suggestedMessageTemplate': '',
            },
        },
        'checkType': 'AUTOMATIC',
        'parentKey': 'ENTIRE_REPORT',
    },
    {
        'key': 'ENTIRE_REPORT:DATE_CONSISTENCY',
        'title': 'Date format consistency verification',
        'stepExplanationTemplates': [
            'Are the dates presented in the same format throughout the entire report?',
        ],
        'suggestedMessageTemplates': {
            'ENTIRE_REPORT:DATE_CONSISTENCY_ERROR': {
                'explanationTemplate': 'The most common date format across the report is: <b>{{date_readable_format}}</b>, The dates with nonconforming format: <b>{{nonconforming_dates}}</b>.',
                'suggestedMessageTemplate': 'The most common date format across the report is: <b>{{date_readable_format}}</b>, The dates with nonconforming format: <b>{{nonconforming_dates}}</b>.',
            },
            'ENTIRE_REPORT:DATE_CONSISTENCY_CORRECT': {
                'explanationTemplate': '',
                'suggestedMessageTemplate': '',
            },
        },
        'checkType': 'AUTOMATIC',
        'parentKey': 'ENTIRE_REPORT',
    },
    {
        'key': 'ENTIRE_REPORT:PBSE',
        'title': 'Verification of ‘subsequent event note’ inclusion',
        'stepExplanationTemplates': [],
        'suggestedMessageTemplates': {
            'ENTIRE_REPORT:PBSE_ERROR': {
                'explanationTemplate': 'No instance of \'subsequent event note\' was detected. According to best practices, it is recommended to include such notes for clarity, even if there are no subsequent significant events requiring disclosure. For further guidance and examples of appropriate wording, please consult our note library.',
                'suggestedMessageTemplate': 'No instance of \'subsequent event note\' was detected. According to best practices, it is recommended to include such notes for clarity, even if there are no subsequent significant events requiring disclosure. For further guidance and examples of appropriate wording, please consult our note library.',
            },
            'ENTIRE_REPORT:PBSE_IDENTIFIED': {
                'explanationTemplate': '',
                'suggestedMessageTemplate': '',
            },
        },
        'checkType': 'AUTOMATIC',
        'parentKey': 'ENTIRE_REPORT',
    },
    {
        'key': 'ENTIRE_REPORT:PERIOD_YEAR',
        'title': 'Check for consistent terminology of timespans',
        'stepExplanationTemplates': ['Is consistent timespan terminology used?'],
        'suggestedMessageTemplates': {
            'ENTIRE_REPORT:PERIOD_YEAR_INCONS': {
                'explanationTemplate': 'Ensure the timespan terminology is consistent in the report. If the report is for 12 months, use "year" across the entire report. For periods other than 12 months, use "period". Based on the selection you made from the dropdown list during upload, the report has been prepared for <b>{{timespan}}</b>. There are <b>{{period_count}}</b> occurrences of "period" and <b>{{year_count}}</b> occurrences of "year" throughout the report.',
                'suggestedMessageTemplate': 'Ensure the timespan terminology is consistent in the report. If the report is for 12 months, use "year" across  the entire report. For periods other than 12 months, use "period". Based on the selection you made from the dropdown list during upload, the report has been prepared for <b>{{timespan}}</b>. There are <b>{{period_count}}</b> occurrences of "period" and <b>{{year_count}}</b> occurrences of "year" throughout the report.',
            },
            'ENTIRE_REPORT:PERIOD_YEAR_CONS': {
                'explanationTemplate': '',
                'suggestedMessageTemplate': '',
            },
        },
        'checkType': 'AUTOMATIC',
        'parentKey': 'ENTIRE_REPORT',
    },
    {
        'key': 'ENTIRE_REPORT:ENT_NAME',
        'title': 'Check for consistent spelling of entity names',
        'stepExplanationTemplates': ['Is the company name spelled consistently across the report?'],
        'suggestedMessageTemplates': {
            'ENTIRE_REPORT:ENT_NAME_INCONS': {
                'explanationTemplate': 'Ensure the entity name is spelled consistently across the report, including the correct use of uppercase and lowercase letters. The following examples of entity names are not consistent with the RCS document: <b>{{incos_ent_name}}</b>',
                'suggestedMessageTemplate': 'Ensure the entity name is spelled consistently across the report, including the correct use of uppercase and lowercase letters. The following examples of entity names are not consistent with the RCS document: <b>{{incos_ent_name}}</b>',
            },
            'ENTIRE_REPORT:ENT_NAME_CONS': {
                'explanationTemplate': '',
                'suggestedMessageTemplate': '',
            },
        },
        'checkType': 'AUTOMATIC',
        'parentKey': 'ENTIRE_REPORT',
    },
]
export const COMERCIAL_COMPANY_REVIEW_STEPS_TREE: ComercialCompanyReviewStepTree[] = COMERCIAL_COMPANY_REVIEW_STEPS
    .filter(el => !el.parentKey)
    .map(el => ({ ...el, children: COMERCIAL_COMPANY_REVIEW_STEPS.filter(child => child.parentKey === el.key) }))
