import React from 'react';

import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Dropdown, Flex, MenuProps, Progress, Tag, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { message } from 'antd/lib';
import { format } from 'date-fns/format';
import { updateDoc } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { generatePath, NavigateFunction } from 'react-router-dom';

import { normalizeShortBackDate } from '@/utils/date/normalizeShortBackDate.ts';

import { FORM_ITEMS, Period } from './AddNewReportModal';
import { ReportReviewTableItem } from './FilesPage.types.ts';
import { DATE_FORMAT_DAY, DATE_FORMAT_MINUTE } from '../../constants/dates.ts';
import { ROUTES_CONFIG } from '../../constants/routes.ts';
import {
    ReportReview,
    reportProcessingStatusColor,
    reportProcessingStatusName,
    ReportType,
    reportTypeTitles, reportReviewStatusColor, reportReviewStatusName,
} from '../../firestore/api/reportReview.ts';
import { storage } from '../../firestore/config.ts';
import { downloadFile } from '../../firestore/utils/downloadFile.ts';
import {firebaseDownloadUrl} from "@/api/utils/firebase/firebaseDownloadUrl.ts";

const PERIOD_NAME: Record<Period, string> = {
    quarterly: 'Quarterly',
    annual: 'Annual',
    monthly: 'Monthly',
    semiAnnual: 'Semi-Annual',
}

export const columns = (navigate: NavigateFunction): ColumnsType<ReportReviewTableItem> => ([
    {
        width: 60,
        ellipsis: true,
        render: (_, item) => {
            return (
                // Fixme: Use compeleted steps for cals
                <Progress
                    type='circle'
                    percent={Math.ceil(item.progress)}
                    showInfo={false}
                    trailColor='#e6f4ff'
                    strokeWidth={20}
                    size={24}
                />
            )
        },
    },
    {
        title: 'Uploaded At',
        dataIndex: 'uploadedAt',
        key: 'uploadedAt',
        width: 170,
        render: (_, item) => (

            item.uploadedAt ? format(item.uploadedAt.toDate(), DATE_FORMAT_MINUTE) : '—'
        ),
    },
    {
        title: FORM_ITEMS.name.label,
        dataIndex: FORM_ITEMS.name.name,
        key: 'name',
        width: 150,
        ellipsis: true,
    },
    {
        title: FORM_ITEMS.reportType.label,
        dataIndex: FORM_ITEMS.reportType.name,
        key: FORM_ITEMS.reportType.name,
        width: 150,
        render: (value: ReportType) => (
            reportTypeTitles[value] ?? '—'
        ),
    },
    {
        title: FORM_ITEMS.reportDate.label,
        dataIndex: FORM_ITEMS.reportDate.name,
        key: FORM_ITEMS.reportDate.name,
        width: 150,
        render: (data: string) => {
            const d = data.toDate ? data.toDate() : data
        
            return format( normalizeShortBackDate(d), DATE_FORMAT_DAY)
        },
    },
    {
        title: FORM_ITEMS.period.label,
        dataIndex: FORM_ITEMS.period.name,
        key: FORM_ITEMS.period.name,
        width: 150,
        render: (value: Period) => (
            PERIOD_NAME[value]
        ),
    },
    {
        key: 'company',
        title: 'Client',
        dataIndex: ['company', 'name'],
        width: 200,
    },
    {
        // Data Transformed
        key: 'entity',
        title: 'Entity',
        dataIndex: ['entity', 'name'],
        width: 200,
    },
    {
        title: FORM_ITEMS.reviewer.label,
        key: FORM_ITEMS.reviewer.name,
        dataIndex: ['reviewerData', 'displayName'],
        width: 150,
    },
    {
        title: FORM_ITEMS.preparer.label,
        key: FORM_ITEMS.preparer.name,
        dataIndex: ['preparerData', 'displayName'],
        width: 150,
    },
    {
        title: FORM_ITEMS.draftNumber.label,
        key: FORM_ITEMS.draftNumber.name,
        dataIndex: FORM_ITEMS.draftNumber.name,
        width: 100,
    },
    {
        title: 'Review Status',
        dataIndex: 'reviewStatus',
        key: 'reviewStatus',
        render: (data, item: ReportReview) => (
            <Tag color={reportReviewStatusColor[item.reviewStatus]}>
                {reportReviewStatusName[item.reviewStatus]}
            </Tag>
        ),
        width: 200,
    },
    {
        title: 'Processing Status',
        dataIndex: 'status',
        key: 'status',
        render: (data, item: ReportReview) => (
            <Flex>
                <Tag color={reportProcessingStatusColor[item.status]}>
                    {reportProcessingStatusName[item.status]}
                </Tag>
                {/*{item.errorMessages?.length > 0 && (*/}
                {/*    <Tooltip title={item.errorMessages.join('\n')}>*/}
                {/*        <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>*/}
                {/*    </Tooltip>*/}
                {/*)}*/}
                {(item.errorMessages?.length > 0 && item.status === 'error') && (
                    <Tooltip title="Some automatic steps can't be fulfilled">
                        <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>
                    </Tooltip>
                )}
            </Flex>
        ),
        width: 200,
    },
    {
        title: 'Actions',
        key: 'actions',
        fixed: 'right',
        width: 130,
        render: (_, item) => {
            const items: MenuProps['items'] = [
                {
                    key: '1',
                    label: (
                        <Typography.Link
                            onClick={() => {
                                firebaseDownloadUrl(item.reportFile).then((url) =>{
                                    downloadFile(url, item.name)
                                }).catch(() => {
                                    message.error('Can\'t download the file')
                                })
                            }}
                        >
                    Download Report
                        </Typography.Link>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <Typography.Link
                            onClick={() => {
                                item?.navigate(generatePath(ROUTES_CONFIG.REVIEW_EDIT.path, { id: item.docRef?.id }))
                            }}
                        >
                    Edit
                        </Typography.Link>
                    ),
                },
                {
                    key: '3',
                    label: (
                        <Typography.Link
                            onClick={async () => {
                                if(!item.docRef) return;
                                await updateDoc(item.docRef, { archived: true })
                            }}
                        >
                    Archive
                        </Typography.Link>
                    ),
                },
            ];

            const disabled = item.status === 'initial' || item.status === 'processing'
            
            return (
                <Tooltip title={disabled ? 'The report validation is underway and should be ready for review in about 2 minutes' : undefined}>
                    <Dropdown.Button
                        disabled={disabled}
                        icon={<DownOutlined/>}
                        menu={{ items }}
                        placement='bottomRight'
                        onClick={() => navigate(generatePath(ROUTES_CONFIG.REVIEW_REPORT.path, { id: item.docRef?.id }))}
                        type='primary'
                    >
                    Open
                    </Dropdown.Button>
                </Tooltip>
            )
        },
    },
]);
