import { collection, Timestamp } from 'firebase/firestore';

import { db } from '../config.ts';

export interface AiMessageAnnotation {
    text: string
    start_index: number
    end_index: number
    file_name: string
}

export interface AiMessage {
    companyId: string;
    reportId: string;
    userId: string;
    message: string;
    createdAt: Timestamp;
    answeredAt: Timestamp | null;
    assistantId: string;
    role: 'user' | 'assistant';
    annotations:AiMessageAnnotation[]
    status: 'messageCreated' | 'generatingAnswer' | 'error' | 'completed';
}

export const AI_MESSAGES_COLLECTION = 'aiMessage'

export const aiMessagesRef = collection(db, AI_MESSAGES_COLLECTION)
