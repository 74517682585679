import { FILE_FORMAT_BY_TYPE } from '../../constants/fileFormats.ts';

export enum ReportType {
    'reportFile' = 'reportFile',
    'suppFileRcs' = 'suppFileRcs',
    'suppFileMapping' = 'suppFileMapping',
    'suppFilePriorYearFinStatement' = 'suppFilePriorYearFinStatement',
    'suppFileTrialBalance' = 'suppFileTrialBalance',
}

export type SupportFileType = 'application/pdf'

export interface GetFilepathParams {
    companyId: string
    uuid: string
    reportType: ReportType
    fileType: SupportFileType
}

export const getReviewFilepath = ({
    companyId, uuid, fileType, reportType,
}: GetFilepathParams) => {
    const supportedFileTypes = Object.values(ReportType)
    
    if (!supportedFileTypes.includes(reportType)) {
        throw new Error(`Unsupported file type: ${fileType}`)
    }

    // TODO: Implement this version finally
    // return `companies/${companyId}/${userCompanyId}/${userEntity}/${fileType}/${draftVersion}/${uuid}.${FILE_FORMAT_BY_TYPE[fileType]}`
    return `companies/${companyId}/${reportType}/${uuid}.${FILE_FORMAT_BY_TYPE[fileType]}`
}
