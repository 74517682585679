import { collection } from 'firebase/firestore';

import { StepKeys } from '../../../backend/configs/lib/configs/steps/steps.types';
import { db } from '../config.ts';

export interface ReportAnnotation {
    page: number;
    stepKey: StepKeys
    format: string
    anotation: string
    companyId: string
    reportId: string
    annotationId: string
}

export const reportAnnotationRef = collection(db, 'reportAnnotation')
