import React, { useMemo, useState } from 'react'

import { addDoc } from '@firebase/firestore';
import { useAsyncEffect } from 'ahooks';
import { Alert, Button, Flex, Typography } from 'antd';
import { query, updateDoc, where } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

import { ReviewStepStatus, reviewStepStatusRef } from '@/firestore/api/reviewStepStatus.ts';
import { getStepsByType, getStepsTreeByType } from '@/utils/getStepsByType.ts';

import { StepsFinishedProps } from './StepsFinished.types'

export const StepsFinished = (props: StepsFinishedProps) => {
    const { reviewSnap } = props

    const [reviewStepStatusStap, reviewStepStatusLoading] = useCollection<ReviewStepStatus>(query(reviewStepStatusRef, where('reportId', '==', reviewSnap.id)))

    const [loading, setLoading] = useState(false)
    
    const reportType = reviewSnap.data()?.reportType

    const stepsConfig = useMemo(() => getStepsByType(reportType).filter(el => el.parentKey?.length), [reportType])
    const stepTreeConfig = useMemo(() => getStepsTreeByType(reportType), [reportType])

    const unsubmited = stepsConfig?.filter((step) => !reviewStepStatusStap?.docs.find((doc) => doc.data().step === step.key))

    useAsyncEffect(async () => {
        if(!unsubmited.length) {
            await updateDoc(reviewSnap.ref, { reviewStatus: 'finished', currentStep: null })
        }
    }, [unsubmited]);
    
    return (
        <Flex vertical gap={16}>
            <Alert
                message='Submit your remaining steps before finalizing the review'
                type='warning'
                showIcon
            />
            <Flex vertical gap={4}>
                {stepTreeConfig?.map((parent) => {
                    const childrenFiltered = parent.children.filter((step) => !reviewStepStatusStap?.docs.find((doc) => doc.data().step === step.key))

                    if(!childrenFiltered.length) {
                        return null
                    }

                    return (
                        <Flex vertical>
                            <Typography.Title level={5}>
                                {parent.title}
                            </Typography.Title>
                            <Flex vertical gap={8}>
                                {childrenFiltered.map((child) => {
                                    return (
                                        <Flex gap={8} align='center'>
                                            <div style={{ height: 8, width: 8, background: 'rgba(138, 138, 138, 0.6509803922)', borderRadius: '50%' }}/>
                                            <span>
                                                {child.title}
                                            </span>
                                        </Flex>
                                    )  
                                })}
                            </Flex>
                        </Flex>
                    )
                })}
            </Flex>
            <Flex
                style={{ 
                    position: 'sticky', 
                    padding: '12px 8px',
                    background: 'white',
                    // Overlap padding of step content container
                    // FIXME: Use variable
                    bottom: -12, 
                }}
                vertical
                gap={8}
            >
                <Button 
                    type='primary'
                    loading={loading}
                    onClick={async () => {
                        setLoading(true)

                        await Promise.all(unsubmited.map(async (step) => {
                            await addDoc(reviewStepStatusRef, {
                                companyId: reviewSnap.data()?.companyId,
                                reportId: reviewSnap.id,
                                step: step.key,
                                status: 'accepted',
                            })
                        }))

                        setLoading(false)
                    }}
                >
                    Mark Steps as Completed
                </Button>
                <Button 
                    loading={loading}
                    onClick={async () => {
                        setLoading(true)

                        await Promise.all(unsubmited.map(async (step) => {
                            await addDoc(reviewStepStatusRef, {
                                companyId: reviewSnap.data()?.companyId,
                                reportId: reviewSnap.id,
                                step: step.key,
                                status: 'notApplicable',
                            })
                        }))

                        setLoading(false)
                    }}
                >
                    Mark Steps as Not Applicable
                </Button>
                <Button
                    loading={loading}
                    onClick={async () => {
                        if(reviewSnap?.ref) {
                            await updateDoc(reviewSnap.ref, { currentStep: '', reviewStatus: 'inProgress' })
                        }
                    }}
                >
               Back to Steps
                </Button>
            </Flex>
        </Flex>

    )
}
