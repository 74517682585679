import { PresetStatusColorType } from 'antd/es/_util/colors';
import { collection, Timestamp } from 'firebase/firestore';

import { ComercialCompanyStepKeys } from '../../../backend/configs/src/configs/steps/comercialCompanySteps.types.ts';
import { FundStepKeys } from '../../../backend/configs/src/configs/steps/fundSteps.types.ts';
import { Period } from '../../pages/FilesPage/AddNewReportModal';
import { db } from '../config.ts';

type ReportProcessingStatus =
/**
 * Initial
 */
'initial' |
/**
 * Processing in progress
     */
'processing' |
/**
     * Processing is failed
     */
'error' |
/**
     * Review finished
     */
'done'

type ReportReviewStatus =
    'inProgress' |
    /**
     * Steps finished but review is not finalized
     */
    'stepsFinished' |
    'finished'

export const reportProcessingStatusName: Record<ReportProcessingStatus, string> = {
    initial: 'Processing',
    processing: 'Processing',
    error: 'Warning', // Error means small issues with extraction now, we not consider it as error in UI
    done: 'Done',
}

export const reportProcessingStatusColor: Record<ReportProcessingStatus, PresetStatusColorType> = {
    initial: 'processing',
    processing: 'processing',
    error: 'warning',
    done: 'success',
}

export const reportReviewStatusColor: Record<ReportReviewStatus, PresetStatusColorType> = {
    inProgress: 'processing',
    finished: 'success',
}

export const reportReviewStatusName: Record<ReportReviewStatus, string> = {
    inProgress: 'In Progress',
    stepsFinished: 'In Progress',
    finished: 'Finished',
}

export type ReportType = 'commercialCompany' | 'regulatedFund'

export const reportTypeTitles: Record<ReportType, string> = {
    commercialCompany: 'Commercial Company',
    regulatedFund: 'Regulated Funds',
}

export enum ReportReviewAlert {
    invalidTemplate = 'invalidTemplate',
}

/**
 * TODO: Rename to ReportReview
 */
export interface ReportReview {
    reportFile: string
    name: string
    entityId: string
    clientCompanyId: string
    // Format: '2022-01-01'
    reportDate: string
    /**
     * Type of the Entity
     *
     * 'Commercial Company' or 'Regulated Funds'
     */
    reportType: ReportType

    year: string
    /**
     * Progress in steps
     */
    currentStep?: FundStepKeys | ComercialCompanyStepKeys
    /**
     * Progress in percents
     */
    progress: number
    /**
     * Processing Status
     */
    status: ReportProcessingStatus
    /**
     * Review Status
     */
    reviewStatus: ReportReviewStatus
    /**
     * Will be true if the pdf was parsed on our server
     */
    draftVersion: number
    /**
     * High level explanation of the errors happened during the processing
     */
    errorMessages: string[]
    /**
     * True - if the report was removed
     */
    archived?: boolean

    userId: string
    /**
     * Global copmany
     */
    companyId: string
    /**
     * Report period
     */
    period: Period
    /**
     * Id
     */
    reviewer: string
    /**
     * Id
     */
    preparer: string

    uploadedAt: Timestamp

    /**
     * Ids of the alerts that were closed by the user
     */
    closedAlerts: ReportReviewAlert[]

    /**
     * Report + annotations
     */
    mergedPdfGenerated?: boolean

    /**
     * Path to merged pdf
     */
    reportWithAnnotations?: string

    /**
     * Need this for the button to be disabled for 10 minutes
     */
    lastMergedPdfGeneration?: Timestamp

    templateValid?: boolean
}

export const reportReviewRef = collection(db, 'reportReviews')
