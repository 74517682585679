import React from 'react'

import { Flex, Table , Tree } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DataNode } from 'antd/es/tree';
const { DirectoryTree } = Tree;

import { SupplemendaryFilesPageProps } from './SupplemendaryFilesPage.types'

interface DataType {
    company: string
    entity: string
    year: number
    version: number
    report: string
}

const treeData: DataNode[] = [
    {
        title: 'parent 0',
        key: '0-0',
        children: [
            { title: 'leaf 0-0', key: '0-0-0', isLeaf: true },
            { title: 'leaf 0-1', key: '0-0-1', isLeaf: true },
        ],
    },
    {
        title: 'parent 1',
        key: '0-1',
        children: [
            { title: 'leaf 1-0', key: '0-1-0', isLeaf: true },
            { title: 'leaf 1-1', key: '0-1-1', isLeaf: true },
        ],
    },
];

export const SupplemendaryFilesPage = (props: SupplemendaryFilesPageProps) => {
    const tableItems: DataType[] = [
        {
            company: 'Company 1',
            children: [
                {
                    entity: 'Entity 1',
                    children: [{
                        year: 2021,
                        children: [{
                            version: 1,
                            children: [{
                                report: 'Report 1',
                            }],
                        }],
                    }],
                }],
        },
    ]

    const tableColumns: ColumnsType<DataType> = [{
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
    }, {
        title: 'Entity',
        dataIndex: 'entity',
        key: 'entity',
    }, {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
    }, {
        title: 'Version',
        dataIndex: 'version',
        key: 'version',
    }, {
        title: 'Report',
        dataIndex: 'report',
        key: 'report',
    }]

    return (
        <Flex vertical gap={20} style={{ padding: 16 }}>
            <DirectoryTree
                multiple
                defaultExpandAll
                // onSelect={onSelect}
                // onExpand={onExpand}
                treeData={treeData}
            />
            <Table columns={tableColumns} dataSource={tableItems}/>
        </Flex>
    )
}
