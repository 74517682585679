import React, { useEffect, useRef, useState } from 'react'

import { BlockOutlined, CalculatorOutlined } from '@ant-design/icons';
import { DocumentSnapshot } from '@firebase/firestore';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import * as Sentry from '@sentry/react';
import { useAsyncEffect } from 'ahooks';
import { Flex, Spin, Tooltip, Typography } from 'antd';
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params';

import {
    ReviewIdentifiedBlock,
    ReviewIdentifiedBlockConfidence,
} from '@/firestore/api/reviewIdentifiedBlock.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { IndentifiedBlockItem } from '@/pages/ReviewPage';
import { LocalErrorBoundary } from '@/widgets/CriticalErrorPlaceholder';
import { CustomToolNames } from '@/widgets/PdfViewer2';
import { createAnnotation } from '@/widgets/PdfViewer2/PdfViewer2.utils.ts';

import { IdentifiedBlocksProps } from './IdentifiedBlocks.types'

export const identifiedBlockColorByConfidence = (pdfInstance: WebViewerInstance): Record<ReviewIdentifiedBlockConfidence, Core.Annotations.Color> => {
    return ({
        // neutral: new pdfInstance.Core.Annotations.Color(0, 0, 0),
        neutral: new pdfInstance.Core.Annotations.Color(255, 205, 69),
        invalid: new pdfInstance.Core.Annotations.Color(255, 77, 79),
        valid: new pdfInstance.Core.Annotations.Color(82, 196, 26),

        link: new pdfInstance.Core.Annotations.Color(45, 85, 255),
    });
}

export const NEED_RELUPDATE_QUERY_CONFIG = {
    name: 'nrl',
    type: withDefault(BooleanParam, false),
}

export const IdentifiedBlocks = Sentry.withProfiler((props: IdentifiedBlocksProps) => {
    const { blocks,blocksId = [], activeStep, loading, messages } = props
    const { annotationManager, pdfInstance, documentViewer } = useViewerDocument()
    const [blocksRelations, setBlocksRelations] = useState<Record<string, Core.Annotations.Annotation>>({})
    const annotCreatedByBlockId = useRef([] as string[])
    const [needRelUpdate, setNeedRelUpdate] = useQueryParam(NEED_RELUPDATE_QUERY_CONFIG.name, NEED_RELUPDATE_QUERY_CONFIG.type)
    const [annotationsReady, setAnnotationsReady] = useState(false)
    const prevIntervalId = useRef<number | null>(null)
    
    // Trigger update for sure
    const blocksUniqueKey = blocks?.map((el) => el.id).join(',')
    
    useAsyncEffect(async () => {
        if(!documentViewer) return

        // Supposedly it's the beset time when we can start draw annotatations
        documentViewer.getAnnotationsLoadedPromise().then(() => {
            setAnnotationsReady(true)
        }).catch((e) => {
            console.error('Error loading annotations', e)
        })
    }, [documentViewer])
    
    // Update relations every time when blocks updated or annotationManager changed
    useEffect(() => {
        const cb = () => {
            const newRelations: Record<string, Core.Annotations.Annotation> = {}

            for(const block of blocks || []) {
                const annotId = block.data().annotaionId
                const annotation = annotationManager?.getAnnotationById(annotId)

                if(annotation) {
                    newRelations[block.id] = annotation
                }
            }
            setBlocksRelations({ ...blocksRelations, ...newRelations })
        }

        // Hack for the case if something wasn't mapped
        if(needRelUpdate) {
            cb()
            setNeedRelUpdate(false)
        }
                
        annotationManager?.addEventListener('annotationChanged', cb )

        return () => {
            annotationManager?.removeEventListener('annotationChanged', cb)
        }
    }, [annotationManager, blocksUniqueKey, needRelUpdate]);

    const createAnnotForBlock = async (block: DocumentSnapshot<ReviewIdentifiedBlock>) => {
        const annotation: ReviewIdentifiedBlock = block.data()

        if(block.data()?.annotaionId) {
            console.error('Annotation already created for block', block.id)
            return
        }

        try {
            const confidence = annotation.manualConfidence || annotation.autoConfidence || 'neutral'

            await createAnnotation({
                annotationManager,
                pdfInstance: pdfInstance,
                type: confidence,
                pageIndex: annotation.pageIndex,
                coordinates: annotation.coordinates,
                toolName: CustomToolNames.TickMark,
                relatedSnapshotId: block.id,
                customData: {
                    relatedStep: activeStep,
                    tickConfidence: confidence,
                    toolName: CustomToolNames.TickMark,
                    // Trigger only common scenario
                    annotationOnlyWhenCreate: 'true',
                },
            })
        } catch (e) {
            debugger
            console.error('Error creating annotation', annotation, e)
        }
    }

    // FIXME: Move to 'useCreateAnnotationsForIdentifiedBlocks'
    //  It should be high level hook which will create annotations avaiable without step guide (summary report for example)
    // Create annotations for new blocks (back generated)
    useAsyncEffect(async () => {
        if(!annotationManager || !annotationsReady) return

        const cb = async () => {
            const blocksWithoutAnnotation =
                blocks?.filter((el) => !el.data().annotaionId && el.data().stepKey === activeStep) ?? []

            if (blocksWithoutAnnotation.length === 0) {
                prevIntervalId.current && (clearInterval(prevIntervalId.current))
                return
            }

            for (const block of blocksWithoutAnnotation) {
                await createAnnotForBlock(block)
            }
        }

        cb() // First launch instantly because setInterval will wait 2 seconds

        prevIntervalId.current && (clearInterval(prevIntervalId.current))

        prevIntervalId.current = setInterval(cb, 2000) // Trigger revalidation every 2 seconds if blocks are created
        
        return () => {
            clearInterval(prevIntervalId.current)
        }
    }, [
        blocksId,
        annotationManager,
        loading,
        blocksUniqueKey,
        pdfInstance,
        activeStep,
        blocks,
        annotationsReady,
    ]);

    if(loading) {
        return (
            <Flex align='center' justify='center'>
                <Spin/>
            </Flex>
        )
    }

    const totalsBlocks = blocks?.filter((el: DocumentSnapshot<ReviewIdentifiedBlock>) => el.data()?.blockType === 'totalValidation') || []
    const relatedBlocks = blocks?.filter((el: DocumentSnapshot<ReviewIdentifiedBlock>) => el.data()?.blockType !== 'totalValidation') || []
    
    return (
        <>
            {relatedBlocks.length > 0 && (
                <>
                    <Flex gap={4} justify='baseline'>
                        <Tooltip placement='left' title='Items generated automatically for this step'><BlockOutlined style={{ color: '#13C2C2' }}/></Tooltip>
                        <Typography.Text strong>Validated Elements</Typography.Text></Flex>
                    <Flex gap={4} vertical>
                        {relatedBlocks.filter((el: DocumentSnapshot<ReviewIdentifiedBlock>) => el.data()?.blockType !== 'totalValidation').map((block) => (
                            <LocalErrorBoundary noContent key={block.id}>
                                <IndentifiedBlockItem
                                    annotation={blocksRelations[block.id]}
                                    block={block}
                                    messages={messages?.filter(mesg => mesg?.data().label === block.data().label)}
                                />
                            </LocalErrorBoundary>
                        ))}
                    </Flex>
                </>
            )}
        
            {totalsBlocks.length > 0 && (
                <>
                    <Flex gap={4} justify='baseline'>
                        <Tooltip placement='left' title='Items generated automatically for this step'><CalculatorOutlined style={{ color: '#13C2C2' }}/></Tooltip>
                        <Typography.Text strong>Totals</Typography.Text></Flex>
                    <Flex gap={4} vertical>
                        {totalsBlocks.map((block) => (
                            <LocalErrorBoundary noContent>
                                <IndentifiedBlockItem
                                    annotation={blocksRelations[block.id]}
                                    key={block.id}
                                    block={block}
                                    messages={
                                        messages?.filter(mesg => mesg?.data().label === block.data().label)
                                    }
                                />
                            </LocalErrorBoundary>
                        ))}
                    </Flex>
                </>
            )}
        </>
    )
})
