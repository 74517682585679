import { collection } from 'firebase/firestore';

import { db } from '../config.ts';

export interface ReviewLinkedObjectsItem {
    content: string;
    coords: [number, number, number,number];
    label: string;
    page: number;
    linksCreated?: boolean;
}

export interface ReviewLinkedObjects {
    companyId: string;
    label: string;
    reportId: string;
    linksGroup: ReviewLinkedObjectsItem[]
}

export const REVIEW_LINKED_OBJECTS_COLLECTION = 'reviewLinkedObjects'

export const reviewLinkedObjectsRef = collection(db, 'reviewLinkedObjects')
