import React, { useEffect, useState } from 'react'

import * as Sentry from '@sentry/react';
import { useVeltClient, VeltCursor, VeltPresence } from '@veltdev/react';
import { Alert, Flex, Spin } from 'antd';
import { message } from 'antd/lib';
import { doc, updateDoc } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useHotkeys } from 'react-hotkeys-hook';
import { useMatch, useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { LocalErrorBoundary } from '@/widgets/CriticalErrorPlaceholder';
import { PdfViewer2 } from '@/widgets/PdfViewer2';
import { SumSelectMode } from '@/widgets/SumSelect';
import { SUM_SELECT_MODE_QUERY_CONFIG } from '@/widgets/SumSelect/SumSelect.contants.ts';

import { GuidePanel } from './GuidePanel';
import { firebaseDownloadUrl } from '../../api/utils/firebase/firebaseDownloadUrl.ts';
import { ROUTES_CONFIG } from '../../constants/routes.ts';
import { ReportReview, ReportReviewAlert, reportReviewRef } from '../../firestore/api/reportReview.ts';
import { MagicButtons } from '../../widgets/MagicButtons';
import { NotesLibrary } from '../../widgets/NotesLibrary';
import { SuggestionsBuilderPage } from '../SuggestionsBuilderPage';

/**
 * @todo Rename to 'GuidedReviewPage'
 */
export const ReviewPage = Sentry.withProfiler(() => {
    const { id: docId } = useParams()
    const [docUrl, setDocUrl] = useState<string>(null)
    const [isDownloading, setIsDownloading] = useState<boolean>(false)
    const { client: veltClient } = useVeltClient()
    const [sumSelectMode, setSumSelectMode] = useQueryParam<SumSelectMode>(SUM_SELECT_MODE_QUERY_CONFIG.name, SUM_SELECT_MODE_QUERY_CONFIG.type)

    const [reportStapshot, isLoading ] = useDocument<ReportReview>(doc(reportReviewRef, docId))

    const data = reportStapshot?.data()
    
    useEffect(() => {
        if(!docId || !veltClient) return
        veltClient.setDocumentId(docId as string)
    }, [veltClient, docId]);

    useEffect(() => {
        if(isLoading || !data) return

        firebaseDownloadUrl( data.reportFile).then((url) =>{
            setDocUrl(url)
        }).catch((e) => {
            message.error('Can\'t generate link for the file', e)
        }).finally(() => {
            setIsDownloading(false)
        })
    }, [data, isLoading]);

    // FIXME: Doesn't work in iframe
    useHotkeys('ctrl+shift+a', () => setSumSelectMode(SumSelectMode.ADD));
    useHotkeys('ctrl+shift+e', () => setSumSelectMode(SumSelectMode.TOTAL));

    const isBuilderMode = useMatch(ROUTES_CONFIG.SUGGESTIONS_BUILDER.path)
    
    if (isDownloading) {
        return <Spin size='large'/>
    }

    // Need to keep strict match with boolean for migration
    const showWrongTemplateAlert = !data?.closedAlerts?.includes(ReportReviewAlert.invalidTemplate) && data?.templateValid === false

    return (
        <>
            {showWrongTemplateAlert && (
                <Alert
                    message="It seems the document you've uploaded isn't currently supported by our platform. However, you can still use our manual tools to get the job done."
                    type='warning'
                    showIcon
                    closable={true}
                    onClose={() => {
                        if(isLoading || !reportStapshot) return
                        const closed = [...(data?.closedAlerts || []), ReportReviewAlert.invalidTemplate]
                        updateDoc(reportStapshot.ref, { closedAlerts: closed })
                    }}
                />
            )}
            <Flex style={{ height: '100%' }}>            
                {/*<VeltComments*/}
                {/*    hotkey={true}*/}
                {/*    allowedElementIds={['velt-pdf-viewer']}*/}
                {/*/>*/}
                <VeltCursor/>
                <Flex style={{ flexGrow: 1 }}>
                    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                        {isBuilderMode ? (
                            <SuggestionsBuilderPage/>
                        ) : (
                            <>
                                <Flex style={{ position: 'absolute', left: 16, top: 44 + 16 }}>
                                    <VeltPresence flockMode={true} self={false}/>
                                </Flex>
                                <MagicButtons/>

                                {/*<PdfViewer*/}
                                {/*    fileUrl={docUrl}*/}
                                {/*/>*/}
                                <LocalErrorBoundary>
                                    <PdfViewer2
                                        fileUrl={docUrl}
                                    />
                                </LocalErrorBoundary>
                            </>
                        )}
                    </div>
                </Flex>
                <Flex style={{ background: 'white', display: 'flex', width: 440, flexShrink: 0 }}>
                    <LocalErrorBoundary>
                        <GuidePanel
                            reviewItemData={data}
                        />
                    </LocalErrorBoundary>
                </Flex>

                <NotesLibrary/>
            </Flex>
        </>
    )
})
