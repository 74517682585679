import { useContext, useEffect, useState } from 'react'

import { PlusOutlined } from '@ant-design/icons';
import {
    Flex,
    Table, Typography, Button,
} from 'antd';
import { doc, getDoc, query, where } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Outlet, useNavigate } from 'react-router-dom';

import { columns } from './FilesPage.constants.tsx';
import { ReportReviewTableItem } from './FilesPage.types.ts';
import { AuthData, AuthDataContext } from '../../components/containers/AuthContext';
import { ROUTES_CONFIG } from '../../constants/routes.ts';
import { clientCompanyRef } from '../../firestore/api/clientCompany.ts';
import { companyEntityRef } from '../../firestore/api/companyEntity.ts';
import { ReportReview, reportReviewRef } from '../../firestore/api/reportReview.ts';

/**
 * TODO: rename to ReportsOnReviewPage
 */
export const FilesPage = () => {
    const navigate = useNavigate()
    const authData = useContext<AuthData>(AuthDataContext)

    // TDDO: user form
    const [filters, setFilters] = useState({
        name: '',
    })
    
    const [reportsTableDataSource, setReportsTableDataSource] = useState<ReportReviewTableItem[]>([])

    // TODO: User filter inputs
    // const reportRefWithFilters = query(reportReviewRef, where('name', 'in', filters.name))
    
    const [reportReviewSnapshot, reportReviewLoading] = useCollection<ReportReview>(
        query( reportReviewRef,
            where('companyId', '==', authData.company.id),
        ),
    )
    
    useEffect(() => {
        if(!reportReviewSnapshot?.size) return;

        const modifiedItems: ReportReviewTableItem[] = reportReviewSnapshot.docs
            .map(el => ({ ...el.data(), docRef: el.ref }))
            .sort((a, b) => {
                return (b.uploadedAt?.toDate?.().getTime() || 0) - (a.uploadedAt?.toDate?.().getTime() || 0)
            })
    
           ;(async () => {
            await Promise.all(
                modifiedItems.map(async (report) => {
                    const [
                        entity, 
                        company,
                    ] = await Promise.all([getDoc(doc(companyEntityRef, report.entityId)), getDoc(doc(clientCompanyRef, report.clientCompanyId))])

                    report.entity = entity.data()
                    report.company = company.data()
                    report.navigate = navigate
                    report.preparerData = authData.usersInCompany.find(user => user.uid === report.preparer) 
                    report.reviewerData = authData.usersInCompany.find(user => user.uid === report.reviewer) 
                }),
            )

            // TODO: Move this logic to firebase level and filter archieved items out by default
            const filteredReports = modifiedItems.filter((item) => !item.archived)

            setReportsTableDataSource(filteredReports)
        })()
    }, [reportReviewSnapshot])
    
    return (
        <>
            <Flex vertical gap={20} style={{ padding: 16, height: '100%' }}>
                <Flex
                    align='baseline' justify='space-between' gap={16}
                >
                    <Typography.Title level={4}>{ROUTES_CONFIG.REVIEW.title}</Typography.Title>
                    <Button
                        icon={<PlusOutlined/>}
                        type='primary'
                        onClick={() => {
                            navigate(ROUTES_CONFIG.REVEIW_NEW.path)
                        }}
                        style={{
                            width: 'unset',
                        }}
                    >
                        Upload Report
                    </Button>
                </Flex>
                {/*<Space >*/}
                {/*    <Checkbox>Assigned to me</Checkbox>*/}
                {/*    <Divider type='vertical'/>*/}
                {/*    <Search*/}
                {/*        allowClear*/}
                {/*        placeholder='Search by File Name' style={{ width: 250 }}*/}
                {/*        required*/}
                {/*        onChange={() => {*/}
                {/*            setFilters({*/}
                {/*                ...filters,*/}
                {/*                name: e.target.value,*/}
                {/*            })*/}
                {/*        }}*/}
                {/*    />*/}
                {/*          */}
                {/*    <Select*/}
                {/*        placeholder='Select Entity'*/}
                {/*        // defaultValue='lucy'*/}
                {/*        style={{ width: 120 }}*/}
                {/*        onChange={() => {}}*/}
                {/*        options={[*/}
                {/*            { value: 'jack', label: 'Jack' },*/}
                {/*            { value: 'lucy', label: 'Lucy' },*/}
                {/*            { value: 'Yiminghe', label: 'yiminghe' },*/}
                {/*            { value: 'disabled', label: 'Disabled', disabled: true },*/}
                {/*        ]}*/}
                {/*    />*/}
                {/*        */}
                {/*    <Select*/}
                {/*        placeholder='Select Year'*/}
                {/*        // defaultValue='lucy'*/}
                {/*        style={{ width: 120 }}*/}
                {/*        onChange={() => {}}*/}
                {/*        options={[*/}
                {/*            { value: 'jack', label: 'Jack' },*/}
                {/*            { value: 'lucy', label: 'Lucy' },*/}
                {/*            { value: 'Yiminghe', label: 'yiminghe' },*/}
                {/*            { value: 'disabled', label: 'Disabled', disabled: true },*/}
                {/*        ]}*/}
                {/*    />*/}
                {/*</Space>*/}
                <Flex style={{ flexGrow: 1 }}>
                    <Table
                        loading={reportReviewLoading}
                        columns={columns(navigate)}
                        dataSource={reportsTableDataSource}
                        bordered
                        scroll={{ scrollToFirstRowOnChange: true, y: '100%' }}
                    />
                </Flex>
            </Flex>
            <Outlet/>
        </>
    )
}
