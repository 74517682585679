import React from 'react'

import { Typography } from 'antd';
import parse, { domToReact } from 'html-react-parser';

import { StyledTextProps } from './StyledText.types'

export const StyledText = (props: StyledTextProps) => {
    const { children = '' } = props

    return (
        <Typography.Text>{parse(children,{
            replace: (domNode) => {
                if (domNode.name === 'b') {
                    return <Typography.Text code>{domToReact(domNode.children)}</Typography.Text>;
                }
            },
        })}
        </Typography.Text>
    )
}
