import React, { useState } from 'react'

import { DownloadOutlined, SyncOutlined } from '@ant-design/icons';
import { Alert, Button, Flex, Result, Tooltip } from 'antd';
import { message } from 'antd/lib';
import { doc, updateDoc } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';

import { firebaseDownloadUrl } from '@/api/utils/firebase/firebaseDownloadUrl.ts';
import http from '@/api/utils/http.ts';
import { ReportReview, reportReviewRef } from '@/firestore/api/reportReview.ts';
import { downloadFile } from '@/firestore/utils/downloadFile.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';

import { SuccessReviewProps } from './SuccessReview.types'

export const SuccessReview = (props: SuccessReviewProps) => {
    const { id: docId } = useParams()
    const [reportSnapshot, isReportDataLoading ] = useDocument<ReportReview>(doc(reportReviewRef, docId))

    const [generatingPdf, setGeneratingPdf] = useState(false)
    const [downloaded, setDownloaded] = useState(false)

    const { annotationManager, documentViewer } = useViewerDocument()
    
    const reportData = reportSnapshot?.data()
    
    const mergedPdfGenerated = reportData?.mergedPdfGenerated ?? false

    const handleGeneratePdf = async () => {
        try {
            const xfdfString = await annotationManager.exportAnnotations();

            setGeneratingPdf(true);
            await http?.post('/config/process-xfdf', { xfdfString, reportId: docId })
            setGeneratingPdf(false);

            message.success('PDF with annotations has been generated successfully.')
        } catch (e) {
            console.error('Failed to generate PDF with annotations:', e)
            message.error('Failed to generate PDF with annotations.')
            setGeneratingPdf(false)
        }
    }
    
    return (
        <Flex
            align='center' 
            vertical
            gap={8}
        >
            {downloaded && (
                <Alert
                    message='Green and yellow Tick Marks along with Links are excluded from the final report'
                    type='warning'
                    showIcon
                    closable
                />
            )}
            <Result
                status='success'
                title='Review successfully completed!'
                // TODO: include this message about notifications when emails ready
                // subTitle='The preparer has already been notified about the review results. The summary report is already available.'
                subTitle='Review complete! Download your summary report or generate a PDF with the annotations included.'
            />
            <Flex vertical gap={8}>
                <Button
                    type='primary'
                    loading={isReportDataLoading}
                    href={reportData?.reportType === 'regulatedFund' ? '/summary_report_funds.xlsx' : '/summary_report_cc.xlsx'}
                    icon={<DownloadOutlined/>}
                >
                    Download Summary Report
                </Button>
                {mergedPdfGenerated ? (
                    <Flex gap={8}>
                        <Button
                            type='primary'
                            icon={<DownloadOutlined/>}
                            loading={generatingPdf}
                            onClick={async () => {
                                firebaseDownloadUrl(reportData.reportWithAnnotations).then((url) =>{
                                    downloadFile(url, reportData.name + '_with_annotations.pdf')
                                })
                                    .then(() => {
                                        setDownloaded(true)
                                    })
                                    .catch(() => {
                                        message.error('Can\'t download the file')
                                    })
                            }}
                        >
               Download Annotations (Acrobat Reader)
                        </Button>
                        <Tooltip placement='left' title='Refresh the PDF to include new annotations. Available once every 10 minutes.'>
                            <Button
                                disabled={reportData?.lastMergedPdfGeneration && reportData?.lastMergedPdfGeneration.toMillis() + 600000 > Date.now()}
                                onClick={handleGeneratePdf}
                                loading={generatingPdf}
                                icon={<SyncOutlined/>}
                            />
                        </Tooltip>
                    </Flex>
                ) : (
                    <Button
                        loading={generatingPdf}
                        onClick={handleGeneratePdf}
                    >
                        Generate PDF with Annotations
                    </Button>
                )}
             
                <Button
                    onClick={async () => {
                        if(reportSnapshot?.ref) {
                            await updateDoc(reportSnapshot.ref, { currentStep: '', reviewStatus: 'inProgress' })
                        }
                    }}
                >
               Back to Steps
                </Button>
            </Flex>
        </Flex>
    )
}
