import React from 'react'

import { CheckSquareOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export const SubchecksTooltip = () => {
    return (
        <Tooltip placement='left' title='Check these boxes to confirm that these checks are completed'><CheckSquareOutlined style={{ color: '#13C2C2' }}/></Tooltip>
    )
}
