/**
 * Comments by step which will be generated as a result of backend script
 */
import { PresetColorType, PresetStatusColorType } from 'antd/es/_util/colors';
import { LiteralUnion } from 'antd/es/_util/type';
import { collection } from 'firebase/firestore';

import { StepKeys } from '../../../backend/configs/src/configs/steps/steps.types.ts';
import { db } from '../config.ts';

export type ErrorType = 'system' | 'consistency' | 'spelling' | 'missing' | 'ai' | 'mismatch' | 'totalValidation'

export const TAG_COLOR_BY_ERROR_TYPE: Record<ErrorType, LiteralUnion<PresetColorType | PresetStatusColorType>> = {
    system: 'error',
    consistency: 'orange',
    spelling: 'gold',
    missing: 'purple',
    ai: 'blue',
    mismatch: 'purple',
    totalValidation: 'magenta',
}

export interface ReviewStepComment {
    /**
     * Step linked to this error
     */
    step: StepKeys
    /**
     * Comment which will be added to the step
     */
    suggestedMessage: string
    /**
     * Any required explanations for this error
     */
    explanation: string
    /**
     * Error type will be used for styling and tags
     */
    errorType: ErrorType
    /**
     * Page where the error was found
     */
    pageNumber?: number
    
    reportOnReviewId: string

    companyId: string
    /**
     * Can be used as a link for messages match
     */
    label?: string
}

export const reviewStepCommentRef = collection(db, 'reviewStepComment')
