import React, { ReactNode, useContext, useEffect } from 'react'

import { CaretDownOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Flex, Select, Space, Tooltip, Typography } from 'antd';
import { updateDoc } from 'firebase/firestore';
import { StringParam, useQueryParam } from 'use-query-params';

import { PdfViewerContext2 } from '@/App.tsx';
import { StyledText } from '@/components/StyledText';
import { ReviewIdentifiedBlockConfidence } from '@/firestore/api/reviewIdentifiedBlock.ts';
import { ReviewStepComment } from '@/firestore/api/reviewStepComment.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { identifiedBlockColorByConfidence, NEED_RELUPDATE_QUERY_CONFIG, StepMessageItem } from '@/pages/ReviewPage';
import { LocalErrorBoundary } from '@/widgets/CriticalErrorPlaceholder';

import { IndentifiedBlockItemProps } from './IndentifiedBlockItem.types'
import {CustomDataKey} from "@/constants/pdfViewer/customDataKey.ts";

interface SelectItem {
    value: ReviewIdentifiedBlockConfidence
    label: ReactNode
}

const items: SelectItem[] = [
    {
        value: 'valid',
        label: <div style={{ height: 14, width: 14, borderRadius: '20%', background: '#52c41a', flexShrink: 0 }}/>,
    },
    {
        value: 'neutral',
        label: <div style={{ height: 14, width: 14, borderRadius: '20%', background: '#ffd254', flexShrink: 0 }}/>,
    },
    {
        value: 'invalid',
        label: <div style={{ height: 14, width: 14, borderRadius: '20%', background: '#ff4d4f', flexShrink: 0 }}/>,
    },
];

export const FOCUSED_ANNOTAION_QUERY_PARAM = {
    name: 'focusedAnnotation',
    type: StringParam,
}

export const IndentifiedBlockItem = (props: IndentifiedBlockItemProps) => {
    const { block, messages, annotation } = props
    const { annotationManager,documentViewer } = useViewerDocument()
    const { pdfInstance } = useContext(PdfViewerContext2)
    const [focusedId, setFocusedId] = useQueryParam(FOCUSED_ANNOTAION_QUERY_PARAM.name,FOCUSED_ANNOTAION_QUERY_PARAM.type)

    const [needRelUpdate, setNeedRelUpdate] = useQueryParam(NEED_RELUPDATE_QUERY_CONFIG.name, NEED_RELUPDATE_QUERY_CONFIG.type)

    const isFocused = focusedId?.length && (focusedId === annotation?.Id)

    useEffect(() => {
        if(!annotation && annotationManager) {
            setNeedRelUpdate(true)
        }
    }, [focusedId, annotation, annotationManager]);
    
    const handleChange = async (value: ReviewIdentifiedBlockConfidence) => {

        if(annotation && annotationManager && pdfInstance) {
            const colorByConf = identifiedBlockColorByConfidence(pdfInstance)[value]

            annotation.StrokeColor = colorByConf;
            annotation.FillColor = colorByConf;
            
            annotation.setCustomData(CustomDataKey.tickConfidence, value)

            annotationManager.redrawAnnotation(annotation)
            annotationManager.updateAnnotation(annotation)

            // In some reason event won't be created automatically
            annotationManager.trigger('annotationChanged', [[annotation], 'modify', { imported: false, force: true }]);
        } else {

            console.error('Annotation not found')
        }

        await updateDoc(block.ref, { manualConfidence: value })
    }

    const handleDelete = async (event) => {
        event.stopPropagation()

        try {
            const annot = annotationManager.getAnnotationById(blockData.annotaionId || annotation.Id)
            annotationManager.deleteAnnotations([annot])

            await updateDoc(block.ref, { rejected: true })

            if(!annot) {

                console.error('Annotation not found for the block during deletion')
            }
        } catch (error) {
            console.error('Error updating block', error)
            setNeedRelUpdate(true)
        }
    }

    const blockData = block.data()

    const style = isFocused ? {
        // background: '#f0f0f0'
        boxShadow: 'inset 0 0 0 1px #0453ff',
    } : {}

    return (
        <Flex vertical={true} gap={8}>
            <Flex
                key={blockData.companyId}
                justify='space-between'
                align='center'
                style={{ ...style,cursor: 'pointer' }}
                onClick={() => {
                    if(annotationManager && annotation) {
                        annotationManager.deselectAllAnnotations()
                        annotationManager.jumpToAnnotation(annotation);
                        annotationManager.selectAnnotation(annotation);
                        setFocusedId(annotation.Id)
                    } else {
                        setNeedRelUpdate(true)
                    }
                }}
            >
                <Flex
                    align='center'
                >
                    <Select<ReviewIdentifiedBlockConfidence>
                        options={items}
                        size='small'
                        onSelect={handleChange}
                        defaultValue={blockData.manualConfidence || blockData.autoConfidence}
                        variant='borderless'
                        onClick={e => e.stopPropagation()}
                    >
                        <Typography.Text color='blue' >
                            <Space>
                                <CaretDownOutlined/>
                                <div style={{ height: 14, width: 14, borderRadius: '50%', background: 'red', flexShrink: 0 }}/>
                            </Space>
                        </Typography.Text>
                    </Select>
                    <StyledText>{blockData.content as string}</StyledText>
                </Flex>
                <Flex
                    align='center'
                >
                    <Tooltip placement='left' title='Area was identified incorrectly'>
                        <Button
                            type='link'
                            size='small'
                            onClick={handleDelete}
                            icon={<CloseOutlined/>}
                            style={{ color: '#bdb4b4' }}
                        />
                    </Tooltip>
                </Flex>
            </Flex>
            
            <Flex vertical gap={16}>
                {messages?.map((item, index) => (
                    <LocalErrorBoundary noContent key={item.id}>
                        <StepMessageItem
                            data={item.data() as ReviewStepComment}
                            id={item.id}
                            blockAnnotation={annotation}
                            blockCoordinates={blockData.coordinates}
                        />
                    </LocalErrorBoundary>
                ))}
            </Flex>
        </Flex>
    )
}
