import React, { useContext, useMemo } from 'react'

import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Button, Flex, MenuProps, Table, Tag, Typography, Dropdown } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { message } from 'antd/lib';
import { format } from 'date-fns/format';
import { where, query, orderBy } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Outlet, useNavigate } from 'react-router-dom';

import { DATE_FORMAT_MINUTE } from '@/constants/dates.ts';
import { storage } from '@/firestore/config.ts';
import { downloadFile } from '@/firestore/utils/downloadFile.ts';

import { AuthData, AuthDataContext } from '../../components/containers/AuthContext';
import { ROUTES_CONFIG_REG_REP } from '../../constants/routes.ts';
import { RegRepProcess, regRepProcessRef, regRepResultFilesKeys } from '../../firestore/api/regRepProcess.ts';
import {firebaseDownloadUrl} from "@/api/utils/firebase/firebaseDownloadUrl.ts";

const statusColorByName: Record<RegRepProcess['status'], string> = {
    processing: 'processing',
    error: 'error',
    done: 'success',
}

const statusTitleByName: Record<RegRepProcess['status'], string> = {
    processing: 'Processing',
    error: 'Extraction Failed',
    done: 'Success',
}

const validationStatusColorByName: Record<RegRepProcess['validationStatus'], string> = {
    processing: 'processing',
    warning: 'warning',
    failed: 'error',
    noErrors: 'success',
}

const validationStatusTitleByName: Record<RegRepProcess['validationStatus'], string> = {
    processing: 'Validating',
    warning: 'Errors Found',
    noErrors: 'No Errors Found',
    failed: 'Failed',
}

const tableColumns = (username): ColumnsType<RegRepProcess> => ([
    {
        title: 'Uploaded At',
        dataIndex: 'uploadedAt',
        key: 'uploadedAt',
        width: 150,
        render: (value) => (
            format(value.toDate(), DATE_FORMAT_MINUTE)
        ),
    },
    {
        title: 'Client',
        width: 150,
        dataIndex: 'company',
        key: 'company',
        render: (value) => 'Fund Management S.A.',
    },
    {
        title: 'Reporting Period',
        width: 100,
        dataIndex: 'company',
        key: 'company',
        render: (value) => 'Monthly',
    },
    {
        title: 'Type',
        width: 70,
        dataIndex: 'entity',
        key: 'entity',
        render: (value) => 'Final',
    },
    {
        width: 150,
        title: 'Uploaded by',
        dataIndex: 'userId',
        key: 'userId',
        render: (value) => username,
    },
    {
        width: 150,
        title: 'Files Uploaded',
        render: (value, item) => {
            return ['S213, TPTOBS, U11'].map((file) => {
                const uploadedFiles: string[] = []
                
                if(item.fileS213) {
                    uploadedFiles.push('S213')
                }
                if (item.fileTPTOBS) {
                    uploadedFiles.push('TPTOBS')
                }
                if (item.fileU11) {
                    uploadedFiles.push('U11')
                }
                
                return uploadedFiles.join(', ')
            })
        },
    },
    // {
    //     width: 120,
    //     title: 'Processing Status',
    //     dataIndex: 'status',
    //     key: 'status',
    //     render: (value, item) => (
    //         <Tag color={statusColorByName[item.status]}>
    //             {statusTitleByName[item.status]}
    //         </Tag>
    //     ),
    // },
    {
        width: 150,
        title: 'Validation Status',
        dataIndex: 'validationStatus',
        key: 'validationStatus',
        render: (value, item) => {
            // First - show processing progress
            if(item.status === 'processing' || item.status === 'error') {
                return (
                    <Tag color={statusColorByName[item.status]}>
                        {statusTitleByName[item.status]}
                    </Tag>
                )
            }

            return (
                <Tag color={validationStatusColorByName[item.validationStatus]}>
                    {validationStatusTitleByName[item.validationStatus]}
                </Tag>
            )
        },
    },
    {
        title: 'Actions',
        key: 'actions',
        fixed: 'right',
        width: 230,
        render: (_, item) => {
            const items: MenuProps['items'] =
            regRepResultFilesKeys
                .filter((key) => item[key])
                .map((key) => {
                    return (
                        {
                            key: key,
                            label: (
                                <Typography.Link
                                    onClick={() => {
                                        firebaseDownloadUrl(item[key].file).then((url) =>{
                                            downloadFile(url, item[key].name)
                                        }).catch(() => {
                                            message.error('Can\'t download the file')
                                        })
                                    }}
                                >
                                    {item[key].name}
                                </Typography.Link>
                            ),
                        }
                    )
                });

            return (
                <Dropdown.Button
                    menu={{ items }}
                    icon={<DownloadOutlined/>}
                    // onClick={() => {
                    //     if(!item.fileS0213Converted) {
                    //         message.error('No results to download')
                    //         return
                    //     }
                    //
                    //     firebaseDownloadUrl(item.fileS0213Converted.file).then((url) =>{
                    //         downloadFile(url, item.fileS0213Converted.name)
                    //     }).catch(() => {
                    //         message.error('Can\'t download the file')
                    //     })
                    // }}
                    type='primary'
                >
                    Download Results
                </Dropdown.Button>
            )
        },
    },
])

export const RegRepPage = Sentry.withProfiler(() => {
    const authData = useContext<AuthData>(AuthDataContext)
    const navigate = useNavigate()
    const userName = authData.user.displayName
    
    const [regRepProcessSnapshot, regRepProcessLoading] = useCollection<RegRepProcess>(
        // @ts-expect-error
        query( regRepProcessRef, orderBy('uploadedAt', 'desc')),
        where('companyId', '==', authData.company.id),
    )

    const tableData = useMemo(() => {
        const data = regRepProcessSnapshot?.docs.map((doc) => doc.data()) ?? []

        const dataWithClildren = data.map((item) => {
            return item
        })

        return dataWithClildren
    }, [regRepProcessSnapshot])

    if(!authData.company.data()?.features?.includes('showRegRep')) {
        return (
            <Flex vertical gap={20} style={{ padding: 16 }}>
                <Typography.Title level={4}>Regulatory Reporting</Typography.Title>
                <Typography.Text>
                    You don't have access to this feature
                </Typography.Text>
            </Flex>
        )
    }

    return (
        <>
            <Flex vertical gap={20} style={{ padding: 16 }}>
                <Flex
                    align='baseline' justify='space-between' gap={16}
                    flex={1}
                >
                    <Typography.Title level={4}>Regulatory Reporting</Typography.Title>
                    <Button
                        icon={<PlusOutlined/>}
                        type='primary'
                        onClick={() => {
                            navigate(ROUTES_CONFIG_REG_REP.REG_REP_NEW.path)
                        }}
                        style={{
                            width: 'unset',
                        }}
                    >
                        {ROUTES_CONFIG_REG_REP.REG_REP_NEW.title}
                    </Button>
                </Flex>
                <Table
                    loading={regRepProcessLoading}
                    columns={tableColumns(userName)}
                    dataSource={tableData}
                    bordered
                    scroll={{ scrollToFirstRowOnChange: true, y: 500 }}
                />
            </Flex>
            <Outlet/>
        </>
    )
})
