import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import 'pdfjs-dist/web/pdf_viewer.css';
import WebViewer from '@pdftron/pdfjs-express';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { useVeltClient } from '@veltdev/react';
import { and, doc, query, where } from 'firebase/firestore';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';

import { PdfContext, PdfViewerContext2 } from '@/App.tsx';
import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { ReportReview, reportReviewRef } from '@/firestore/api/reportReview.ts';
import { ReviewLinkedObjects, reviewLinkedObjectsRef } from '@/firestore/api/reviewLinkedObjects.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { useApplyAnnotations } from '@/widgets/PdfViewer2/PdfViewer2.hooks.ts';
import { PdfViewer2Props } from '@/widgets/PdfViewer2/PdfViewer2.types.ts';
import { createAnnotation } from '@/widgets/PdfViewer2/PdfViewer2.utils.ts';
import { useAnnotationsListener } from '@/widgets/PdfViewer2/useAnnotationsListener.ts';
import { useToolsListener } from '@/widgets/PdfViewer2/useToolsListener.ts';

import { useAsyncEffect } from 'ahooks';

// Rename to annot type
export const enum CustomToolNames {
    TickMark = 'TickMark',
    SumSelect = 'SumSelect',
    CrossLink = 'CrossLink',
}

const VIEWER_LIB_STATIC_URL = `${window.location.protocol}//${window.location.host}/pdf-viewer/`;

/**
 * FIXME:
 * - Limit right click elements
 *
 * @todo: Wrap with 'Sentry.profiler' for performance monitoring
 *
 * LINKS:
 * 3 popup types: https://docs.apryse.com/documentation/web/guides/customizing-popup/
 */
export const PdfViewer2 = (props: PdfViewer2Props) => {
    const { fileUrl } = props;

    const { id: docId } = useParams()

    const { annotationManager, documentViewer } = useViewerDocument()

    const authData = useContext<AuthData>(AuthDataContext)

    const [reivewLinkObjectsSnap, revewLinkObjectsLoading] = useCollection<ReviewLinkedObjects>(query(reviewLinkedObjectsRef, and(
        // where('companyId', '==', authData.company.id),
        where('reportId', '==', docId),
    )))

    const [reportSnapshot ] = useDocument<ReportReview>(doc(reportReviewRef, docId))

    const reportData = reportSnapshot?.data()

    const instansInited = useRef(false);
    const viewer = useRef(null);
    const { pdfInstance, setPdfInstance } = useContext<PdfContext>(PdfViewerContext2)

    const { client: veltClient } = useVeltClient()

    useToolsListener()
    useAnnotationsListener({
        reportSnapshot,
    })

    const [annotationsReady, setAnnotationsReady] = useState(false)
    
    useAsyncEffect(async () => {
        if(!documentViewer) return

        // Supposedly it's the beset time when we can start draw annotatations
        documentViewer.getAnnotationsLoadedPromise().then(() => {
            setAnnotationsReady(true)
        }).catch((e) => {
            console.error('Error loading annotations', e)
        })
    }, [documentViewer])

    const addCustomTools = useCallback((instance: WebViewerInstance, docViewer: Core.DocumentViewer) => {
        const baseTool = docViewer.getTool(instance.Core.Tools.ToolNames.RECTANGLE2);

        instance.UI.registerTool({
            toolName: CustomToolNames.TickMark,
            toolObject: baseTool,
            buttonImage: '',
        })

        // If I use same base tool for both custom tools, only one tool will be available
        const baseTool2 = docViewer.getTool(instance.Core.Tools.ToolNames.RECTANGLE);

        instance.UI.registerTool({
            toolName: CustomToolNames.SumSelect,
            toolObject: baseTool2,
            buttonImage: '',
        })
    }, [])

    useApplyAnnotations({})

    /**
     * Apply links
     */
    useAsyncEffect(async () => {
        if(!reivewLinkObjectsSnap || !annotationManager || revewLinkObjectsLoading || !pdfInstance || !annotationsReady) return
        if(reportData?.reviewStatus !== 'inProgress') return

        const filtered = reivewLinkObjectsSnap.docs
        // TEmprorary disabled and we don't care about saving an info info database
        //     .filter((linkObject) => {
        //     return !linkObject.data().linksCreated
        // })
        
        for(const linkObject of filtered) {
            const linkData = linkObject.data()

            const groupUniqueKey = linkObject.id
            
            for(const linkItem of linkData.linksGroup) {
                const { coords, page, content, label } = linkItem

                await createAnnotation({
                    annotationManager,
                    pdfInstance: pdfInstance,
                    type: 'link',
                    pageIndex: page,
                    coordinates: coords,
                    showInNotesPanel: false,
                    toolName: CustomToolNames.CrossLink,
                    reply: 'eCDF - Note',
                    customData: {
                        linkGroup: groupUniqueKey,
                        tickConfidence: 'link', // FIXME: Rename later to style/type. Related to Identified blocks type.
                        linkLabel: label,
                        ignoreStepKey: 'true',
                        toolName: CustomToolNames.CrossLink,
                        relatedLinkSnapshotId: linkObject.id,
                        linkIndex: linkData.linksGroup.indexOf(linkItem).toString(),
                    },
                })
            }
        }
    }, [reivewLinkObjectsSnap, annotationManager, revewLinkObjectsLoading, pdfInstance, reportData?.currentStep, annotationsReady]);

    const initVeltComments = useCallback((instance: WebViewerInstance, annotManager: Core.AnnotationManager) => {
        instance.UI.annotationPopup.add(
            {
                type: 'actionButton',
                title: 'Comment',
                img: 'icon-tool-comment-line',
                onClick: () => {
                    const annots = annotManager.getSelectedAnnotations();
                    if (!annots || !annots[0]) {
                        return;
                    }
                    const [annot] = annots;
                    const id = annot.Id

                    annotManager.redrawAnnotation(annot);

                    const locationName = id
                    const locationId = id

                    const locationConfig = {
                        id: locationId,
                        locationName,
                    }

                    veltClient.setLocation(locationConfig)
                    veltClient.setLocation({
                        id: 'step-1',
                        locationName: 'Step 1',
                    }, true)

                    // Activate filter in Sidebar by account
                    const filters = {
                        location: [
                            { id: locationId },
                        ],
                    }
                    const commentElement = veltClient.getCommentElement()
                    commentElement.setCommentSidebarFilters(filters)
                },
            },
        )
    }, [veltClient])

    // Reset Velt filter on pageLoading
    useEffect(() => {
        if(!veltClient || !pdfInstance || !annotationManager) return

        const filters = {
            location: [],
        }
        const commentElement = veltClient.getCommentElement()
        commentElement.setCommentSidebarFilters(filters)

        // FIXME: Return later
        // initVeltComments(pdfInstance, annotationManager)
    }, [veltClient, pdfInstance, annotationManager]);

    useEffect(() => {
        if (instansInited.current || !fileUrl) {
            return;
        }
        instansInited.current = true

        WebViewer(
            {
                path: VIEWER_LIB_STATIC_URL,
                initialDoc: fileUrl,
                licenseKey: 'qu1Vr7UA7e8XcicdCcZ8',
                // Allows to modify annotations from other users
                isAdminUser: true,
            },
            viewer.current,
        ).then((instance: WebViewerInstance) => {
            const docViewer = instance.docViewer as Core.DocumentViewer;

            // Keeps only main tools active on the panel
            instance.UI.disableElements([
                'toolbarGroup-Annotate',
                'toolbarGroup-Insert',
                'toolbarGroup-Shapes',
                'toolbarGroup-Measure',
                'toolbarGroup-Forms',
                'toolbarGroup-Edit',
                'toolbarGroup-FillAndSign',
                'toolbarGroup-View',
                'toolsHeader',
            ]);

            // Turn off comments
            instance.UI.disableElements([
                // FIXME: Uncomment and switch to Velt comments
                // 'annotationCommentButton',
                // 'notesPanelButton',
                // 'notesPanel',
                // 'toggleNotesButton',
            ]);

            // Note tooltip
            instance.UI.disableElements([
                'linkButton',
                'annotationStyleEditButton',
                'annotationGroupBu-tton',
            ]);

            addCustomTools(instance, docViewer)

            setPdfInstance(instance)
        })
            .catch((error) => {
                console.error('Error loading WebViewer:', error);

            })
    }, [fileUrl]);

    useEffect(() => {
        if( !annotationManager) return

        // Identify user
        annotationManager.setCurrentUser(authData.user.displayName)

        /**
         * Disabled multiple annotations selection
         * Annotations listener properly handling now only one annotation
         */
        annotationManager.addEventListener('annotationSelected', (annotations)=>{
            if(annotations.length > 1) {
                annotationManager.deselectAnnotations(annotations.splice(0))
            }

            // TODO: Later name it work only for customTools
            annotations?.forEach((annotation) => {
                annotation.setRotationControlEnabled(false);
            })
        })
    }, [annotationManager]);

    return (
        <div
            id='velt-pdf-viewer'
            data-velt-pdf-viewer='true'
            data-velt-iframe-container='true'
            style={{
                'flex': 1,
                'height': '100%',
            }}
            ref={viewer}
        />
    );
}
