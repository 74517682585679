import { collection } from 'firebase/firestore';

import { db } from '../config.ts';

export interface ReviewStepCheckStatus {
    companyId: string;
    reportId: string;
    checkKey: string;
    stepKey: string;
    checked: boolean;
}

export const REVIEW_STEP_CHECK_STATUS_COLLECTION = 'reviewStepCheckStatus'

export const reviewStepCheckStatusRef = collection(db, 'reviewStepCheckStatus')
