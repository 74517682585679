import React, { useContext } from 'react'

import { addDoc } from '@firebase/firestore';
import { Form, FormItemProps, Modal } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import { message } from 'antd/lib';
import { ref as storageRef } from 'firebase/storage';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useUploadFile } from 'react-firebase-hooks/storage';
import { useNavigate } from 'react-router-dom';

import { FileDragger } from '@/components/base/FileDragger/FileDragger';
import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { storage } from '@/firestore/config.ts';

import { RegRepNewModalProps } from './RegRepNewModal.types'
import { CUSTOM_MODAL_WIDTH } from '../../../constants/modalWidth.ts';
import { ROUTES_CONFIG_REG_REP } from '../../../constants/routes.ts';
import { REG_REP_FORM_ITEMS, RegRepProcess, regRepProcessRef } from '../../../firestore/api/regRepProcess.ts';

export const RegRepNewModal = (props: RegRepNewModalProps) => {
    const [form] = Form.useForm();
    const authData = useContext<AuthData>(AuthDataContext)
    const [uploadFile, uploading] = useUploadFile();
    const navigate = useNavigate()

    const handleFormSubmit = async (data: Partial<RegRepProcess>) => {
        try {
            const newDocPayload: Partial<RegRepProcess> = {
                status: 'processing',
                validationStatus: 'processing',
                userId: authData.user.uid,
                companyId: authData.company.id,
                
                fileTPTOBS: data.fileTPTOBS.file.response.metadata.fullPath,
                fileU11: data.fileU11.file.response.metadata.fullPath,
                uploadedAt: new Date(),
            }
            
            if(data.fileS213?.file?.response?.metadata?.fullPath) {
                newDocPayload.fileS213 = data.fileS213.file.response.metadata.fullPath
            }

            await addDoc(regRepProcessRef, newDocPayload)
        } catch (error) {
            console.error(error)
            message.error('Failed to create new report')
        }
    }

    const handleOk = () => {
        form
            .validateFields()
            .then(async (values) => {
                await handleFormSubmit(values)
                form.resetFields();
                navigate(ROUTES_CONFIG_REG_REP.REG_REP.path)
            })
            .catch((info) => {
                console.error(info)
                message.error('Please fill in all required fields before submitting')
            })
    }
    
    const draggerCustomRequest = async (formItemProps: FormItemProps, data: UploadRequestOption) => {
        data.onProgress({ percent: 0 })

        const file = data.file as RcFile

        const filePath = `reg-rep/companies/${authData.company.id}/${formItemProps.name}/${file.uid}`

        const res = await uploadFile(storageRef(storage, filePath), data.file)

        if(!res) {
            throw new Error('File upload failed')
        }

        // // Save filename in form if main report file
        // if (!form.getFieldValue(FORM_ITEMS.name.name) && formItemProps.name === ReportType.reportFile) {
        //     form.setFieldsValue({ [FORM_ITEMS.name.name]: file.name })
        // }

        data.onProgress({ percent: 100 })
        data.onSuccess(res);
    }
    
    const handleCancel = () => {
        form.resetFields();
        navigate(ROUTES_CONFIG_REG_REP.REG_REP.path)
    }
    
    return (
        <Modal
            open
            confirmLoading={uploading}
            title={ROUTES_CONFIG_REG_REP.REG_REP_NEW.title}
            centered
            onOk={handleOk}
            onCancel={handleCancel}
            width={CUSTOM_MODAL_WIDTH.medium}
            okText='Upload'
            styles={{
                body: {
                    overflowY: 'auto',
                },
                content: {
                    overflowY: 'hidden',
                    maxHeight: '90vh',
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <Form
                form={form}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                onFinish={handleFormSubmit}
                autoComplete='off'
                scrollToFirstError={true}
                layout='vertical'
            >
                <Form.Item
                    {...REG_REP_FORM_ITEMS.fileU11}
                >
                    <FileDragger
                        customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileU11, options)}
                        formats={['xlsx']}
                    />
                </Form.Item>
                <Form.Item
                    {...REG_REP_FORM_ITEMS.fileTPTOBS}
                >
                    <FileDragger
                        customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileTPTOBS, options)}
                        formats={['xlsx']}
                    />
                </Form.Item>
                <Form.Item
                    {...REG_REP_FORM_ITEMS.fileS213}
                >
                    <FileDragger
                        customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileS213, options)}
                        formats={['xlsx']}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}
