import { DocumentSnapshot } from '@firebase/firestore';

import { ReportReview } from '@/firestore/api/reportReview.ts';

export interface SumSelectProps {
    
}

export enum SumSelectMode {
    ADD = 'add',
    TOTAL = 'TOTAL',
    NEW = 'new',
}
