import { getDownloadURL, getStorage, ref } from 'firebase/storage';

/**
 * Transform URL for avoiding CORS issues
 */
export const firebaseDownloadUrl = async (path: string): Promise<string> => {
    const storage = getStorage();
    const url = await getDownloadURL(ref(storage, path));
    
    const parsedUrl = new URL(url);

    const downloadUrl = document.location.origin + parsedUrl.pathname + parsedUrl.search;

    return downloadUrl;
}
