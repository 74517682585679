import React, { useContext, useEffect, useState } from 'react'

import { MessageOutlined, ProfileOutlined } from '@ant-design/icons';
import { VeltCommentTool } from '@veltdev/react';
import { FloatButton } from 'antd';
import { doc } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { ReportReview, reportReviewRef } from '@/firestore/api/reportReview.ts';
import { COMMENT_TOOL_QUERY_CONFIG, NativeCommentButton } from '@/widgets/NativeCommentButton';
import { TickMark } from '@/widgets/TickMark';

import { AI_CHAT_QUERY_CONFIG, SUM_SELECT_QUERY_PARAM, TICK_MARK_QUERY_CONFIG } from './MagicButtons.constants.ts';
import { MagicButtonsProps } from './MagicButtons.types'
import { AiChat } from '../../pages/ReviewPage';
import { NOTES_LIBRARY_QUERY_CONFIG } from '../NotesLibrary/NotesLibrary.constants.ts';
import { SumSelect } from '../SumSelect';

export const MagicButtons = (props: MagicButtonsProps) => {
    const [isNotesLibraryOpen, setIsNotesLibraryOpen] = useQueryParam(NOTES_LIBRARY_QUERY_CONFIG.name, NOTES_LIBRARY_QUERY_CONFIG.type)
    const [isAiChatOpen, setIsAiChatOpen] = useQueryParam(AI_CHAT_QUERY_CONFIG.name, AI_CHAT_QUERY_CONFIG.type)
    const [tickMarkOpen, setTickMarkOpen] = useQueryParam(TICK_MARK_QUERY_CONFIG.name, TICK_MARK_QUERY_CONFIG.type)
    const [isSumSelectOpen, setIsSumSelectOpen] = useQueryParam(SUM_SELECT_QUERY_PARAM.name, SUM_SELECT_QUERY_PARAM.type)
    const [isCommentToolOpen, setIsCommentToolOpen] = useQueryParam(COMMENT_TOOL_QUERY_CONFIG.name, COMMENT_TOOL_QUERY_CONFIG.type)
    const authData = useContext<AuthData>(AuthDataContext)

    const { id: docId } = useParams()
    const [reportSnapshot ] = useDocument<ReportReview>(doc(reportReviewRef, docId))
    const reportData = reportSnapshot?.data()

    const [isVeltButtonActive, setIsVeltButtonActive] = useState(false)

    // Disable tools on load
    useEffect(() => {
        if (tickMarkOpen) {
            setIsNotesLibraryOpen(false)
            setIsAiChatOpen(false)
            setIsSumSelectOpen(false)
            setIsCommentToolOpen(false)
            setTickMarkOpen(false)
        }
    }, []);
    
    useEffect(() => {
        if (isNotesLibraryOpen) {
            setIsAiChatOpen(false)
            setTickMarkOpen(false)
            setIsSumSelectOpen(false)
            setIsCommentToolOpen(false)
        }
    }, [isNotesLibraryOpen]);

    useEffect(() => {
        if (isAiChatOpen) {
            setIsNotesLibraryOpen(false)
            setTickMarkOpen(false)
            setIsSumSelectOpen(false)
            setIsCommentToolOpen(false)
        }
    }, [isAiChatOpen]);

    useEffect(() => {
        if (tickMarkOpen) {
            setIsNotesLibraryOpen(false)
            setIsAiChatOpen(false)
            setIsSumSelectOpen(false)
            setIsCommentToolOpen(false)
        }
    }, [tickMarkOpen]);

    useEffect(() => {
        if (isSumSelectOpen) {
            setIsNotesLibraryOpen(false)
            setIsAiChatOpen(false)
            setTickMarkOpen(false)
            setIsCommentToolOpen(false)
        }
    }, [isSumSelectOpen]);
    
    useEffect(() => {
        if (isCommentToolOpen) {
            setIsNotesLibraryOpen(false)
            setIsAiChatOpen(false)
            setTickMarkOpen(false)
            setIsSumSelectOpen(false)
        }
    }, [isCommentToolOpen]);

    const withVelt = authData.company?.data()?.features?.includes('velt')

    if(reportData?.reviewStatus !== 'inProgress') {
        return null
    }

    return (
        <>
            {/*TODO: Finish. Tickmark color picker base*/}
            {/*<Flex*/}
            {/*    style={{ position: 'absolute', top: 100, right: 24 }}*/}
            {/*    vertical*/}
            {/*    gap={8}*/}
            {/*>*/}
            {/*    <Button type='primary' shape='circle' icon={<SearchOutlined/>}/>*/}
            {/*    <Button type='primary' shape='circle' icon={<SearchOutlined/>}/>*/}
            {/*    <Button type='primary' shape='circle' icon={<SearchOutlined/>}/>*/}
            {/*</Flex>    */}
            
            <FloatButton.Group
                shape='square'
                style={{ position: 'absolute' }}
            >
                <TickMark/>
                <SumSelect/>
                {
                    withVelt ? (
                        <VeltCommentTool
                            onCommentModeChange={(mode: boolean) => {
                                if (mode) {
                                    setIsVeltButtonActive(true)
                                } else {
                                    setIsVeltButtonActive(false)
                                }
                
                                setTickMarkOpen(false)
                            }}
                        >
                            <div slot='button'>
                                <FloatButton
                                    shape='square'
                                    tooltip='Add Comment'
                                    type={isVeltButtonActive ? 'primary' : 'default'}
                                    icon={<MessageOutlined/>}
                                />
                            </div>
                        </VeltCommentTool>
                    ) : (
                        <NativeCommentButton/>
                    )
                }

                <FloatButton
                    type={isNotesLibraryOpen ? 'primary' : 'default'}
                    icon={<ProfileOutlined/>}
                    tooltip='Notes Library'
                    onClick={() => {
                        setIsNotesLibraryOpen(!isNotesLibraryOpen)
                        setIsAiChatOpen(false)
                        setTickMarkOpen(false)
                        setIsSumSelectOpen(false)
                    }}
                />
                <AiChat/>
            </FloatButton.Group>
        </>
    )
}
