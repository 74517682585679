import { useMemo } from 'react'

import { SettingFilled } from '@ant-design/icons';
import { Flex, Typography } from 'antd';
import { useQueryParams } from 'use-query-params';

import {
    AI_CHAT_QUERY_CONFIG, magicButtonNames,
    SUM_SELECT_QUERY_PARAM,
    TICK_MARK_QUERY_CONFIG,
} from '@/widgets/MagicButtons/MagicButtons.constants.ts';

import { SuggestedToolsProps } from './SuggestedTools.types'

const suggestedTools = [
    // AI_CHAT_QUERY_CONFIG,
    SUM_SELECT_QUERY_PARAM,
    TICK_MARK_QUERY_CONFIG,
]

/**
 * TODO: Will be based on sections (conver, notes, etc)
 */
export const SuggestedTools = (props: SuggestedToolsProps) => {
    const { stepConfig } = props
    
    const queryParamsConf = useMemo(() => (
        suggestedTools.reduce((acc, tool) => {
            acc[tool.name] = tool.type
            return acc
        }, {})
    ), [suggestedTools])
    
    const [queryParams, setQueryParams] = useQueryParams(queryParamsConf)

    if(!suggestedTools) return null

    return (
        <>
            <Flex gap={4} justify='baseline'><SettingFilled style={{ color: '#13C2C2' }}/> <Typography.Text strong>Suggested Tools</Typography.Text></Flex>
            {suggestedTools.map((block) => (
                <Typography.Link
                    key={block.name} onClick={() => {
                        setQueryParams({
                            [block.name]: !queryParams[block.name],
                        })
                    }}
                >{magicButtonNames[block.name] || block.name}</Typography.Link>
            ))}
        </>
    )
}
