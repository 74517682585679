import React from 'react'

import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { getAuthConfig } from '@/components/containers/AuthContext';

import { AuthGuardProps } from './AuthGuard.types.ts'

export const AuthGuard = (props: AuthGuardProps) => {
    const { children } = props
    const navigate = useNavigate()

    return (
        <Auth0Provider {...(getAuthConfig(navigate))}>
            {children}
        </Auth0Provider>
    )
}
