import React from 'react'

import ReactDOM from 'react-dom/client'

import App from './App.tsx'
import './index.css'

import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { AuthContext } from '@/components/containers/AuthContext';
import { LocalErrorBoundary } from '@/widgets/CriticalErrorPlaceholder';

import { AuthGuard } from './components/containers/AuthGuard/AuthGuard.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
    <LocalErrorBoundary>
        <React.StrictMode>
            <BrowserRouter>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <AuthGuard>
                        <AuthContext>
                            <App/>
                        </AuthContext>
                    </AuthGuard>
                </QueryParamProvider>
            </BrowserRouter>
        </React.StrictMode>
    </LocalErrorBoundary>,
)
