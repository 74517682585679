import React from 'react'

import { Flex } from 'antd';

import { MessagesProps } from './Messages.types'

export const Messages = (props: MessagesProps) => {
    return (
        <Flex style={{ overflowX: 'auto' }}>
            {/*<VeltCommentsSidebar*/}
            {/*    // onCommentClick={async (payload) => {*/}
            {/*    //     const annotationId = payload.location.id;*/}
            {/*    //*/}
            {/*    //     const { documentViewer, annotationManager } = PDFInstance.Core;*/}
            {/*    //     const annotList = await annotationManager.getAnnotationsList();*/}
            {/*    //     const annotations = await annotationManager.getAnnotationById(annotationId);*/}
            {/*    //     annotationManager.selectAnnotation(annotList[0]);*/}
            {/*    //
            {/*    //     // */}
            {/*    //     // Ищем аннотацию с указанным ID*/}
            {/*    //     // const annotation = annotations.find((anno: any) => anno.id === annotationId);*/}
            {/*    //*/}
            {/*    //     // const pageNumber = annotation.pageNumber;*/}
            {/*    //     // const rect = annotation.rect; // [left, top, right, bottom]*/}
            {/*    //*/}
            {/*    //     // Метод для перевода фокуса на аннотацию*/}
            {/*    //     // PDFInstance.UI.sc(pageNumber, rect);*/}
            {/*    //*/}
            {/*    //     // Выделить аннотацию, если это возможно*/}
            {/*    //     // if (PDFInstance.UI.selectAnnotation) {*/}
            {/*    //     //     PDFInstance.UI.selectAnnotation(annotation.id);*/}
            {/*    //     // }*/}
            {/*    // }}*/}
            {/*    pageMode*/}
            {/*    embedMode*/}
            {/*/>*/}
        </Flex>
    )
}
