import React, { useContext, useState } from 'react';

import { addDoc } from '@firebase/firestore';
import { Button, Flex, Form, Input, Select, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { message } from 'antd/lib';
import { doc, updateDoc } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';

import { AuthData, AuthDataContext } from '../../components/containers/AuthContext';
import { ReportReview, reportReviewRef } from '../../firestore/api/reportReview.ts';
import { ReviewStepComment, reviewStepCommentRef } from '../../firestore/api/reviewStepComment.ts';
import { reviewStepValidationRef } from '../../firestore/api/reviewStepValidation.ts';
import { db } from '../../firestore/config.ts';
import { useLocalStorage } from '../../hooks/useLocalStorage.ts';
import { getStepsByType } from '../../utils/getStepsByType.ts';

/**
 * Use this as a component, not as a page
 */
export const SuggestionsBuilderPage = () => {
    const authData = useContext<AuthData>(AuthDataContext)
    const { id: reportOnReviewId } = useParams()

    const [aiChat, setAiChat] = useLocalStorage<string[]>('aichat', [])

    const [reportSnapshot ] = useDocument<ReportReview>(doc(db, 'reportReviews', reportOnReviewId))

    const [form] = Form.useForm();
    const [formStep] = Form.useForm();

    const [formState, setFormState] = useState({
        step: null,
        suggestedMessageTemplate: '',
    })

    const [formStepState, setFormStepState] = useState({
        step: null,
        explanationTemplate: -1,
        suggestedMessage: '',
    })

    const onFormChange = (values) => {
        setFormState({ ...formState, ...values })
    }

    const onFormStepChange = (values) => {
        setFormStepState({ ...formStepState, ...values })
    }

    const reportType = reportSnapshot?.data()?.reportType

    const activeStep = getStepsByType(reportType).find(step => step.key === formState.step)
    const activeStepStep = getStepsByType(reportType).find(step => step.key === formStepState.step)

    const stepBuilderSubmit = async () => {
        const state = formStep.getFieldsValue()

        const res = await addDoc(reviewStepValidationRef, {
            step: state.step,
            explanations: [state.suggestedMessage],
            companyId: authData.company.id,
            reportOnReviewId,
        })

        form.resetFields()

        message.success('Step item created')
    }

    const errorBuilderSubmit = async () => {
        const state = form.getFieldsValue()

        const newItem: ReviewStepComment = {
            step: state.step,
            explanation: state.explanation,
            suggestedMessage: state.suggestedMessage,
            errorType: state.errorType,
            pageNumber: Number(state.pageNumber),

            companyId: authData.company.id,
            reportOnReviewId: reportOnReviewId as string,
        }

        await addDoc(reviewStepCommentRef, newItem)

        form.resetFields()

        message.success('Error item created')
    }

    const steps = getStepsByType(reportType)

    return (
        <Flex style={{ padding: 16 }} gap={32} vertical>
            <Button
                onClick={async () => {
                    try {
                        await updateDoc(doc(reportReviewRef, reportOnReviewId), {
                            currentStep: null,
                            reviewStatus: 'inProgress',
                        })
                    } catch (e) {
                        console.error('Error while reseting active step', e)
                    }
                }}
            >
                Reset Active Step
            </Button>
            
            <Flex gap={32} wrap='wrap'>
                <Flex gap={8} vertical={true} style={{ width: '50%', minWidth: 500 }}>
                    <Typography.Title level={2}>Suggested Message Builder</Typography.Title>

                    <Form
                        style={{ flexGrow: 1 }}
                        form={form}
                        layout='vertical'
                        onValuesChange={onFormChange}
                    >
                        <Form.Item label='Step' name='step'>
                            <Select
                                options={steps.map(step => ({ label: step.title, value: step.key }))}
                            />
                        </Form.Item>
                        {formState.step && (
                            <Form.Item label='Explanation Message Template' name='suggestedMessageTemplate'>
                                <Select
                                    options={Object.keys(activeStep?.suggestedMessageTemplates).map((template) => ({
                                        label: template,
                                        value: template,
                                    }))}
                                />
                            </Form.Item>
                        )}

                        {(Boolean(formState.suggestedMessageTemplate.length > 0)) && (
                            <Form.Item
                                label='Explanation Message' name='explanation'
                                initialValue={activeStep?.suggestedMessageTemplates[formState.suggestedMessageTemplate].explanationTemplate}
                            >
                                <TextArea/>
                            </Form.Item>
                        )}

                        {(Boolean(formState.suggestedMessageTemplate.length > 0)) && (
                            <Form.Item
                                label='Suggested Message'
                                name='suggestedMessage'
                                initialValue={activeStep?.suggestedMessageTemplates[formState.suggestedMessageTemplate]?.suggestedMessageTemplate}
                            >
                                <TextArea/>
                            </Form.Item>
                        )}

                        <Form.Item label='Error Type' name='errorType'>
                            <Select
                                options={[
                                    { label: 'System', value: 'system' },
                                    { label: 'Consistency', value: 'consistency' },
                                    { label: 'Spelling', value: 'spelling' },
                                    { label: 'Missing', value: 'missing' },
                                    { label: 'AI', value: 'ai' },
                                ]}
                            />
                        </Form.Item>

                        <Form.Item label='Page Number' name='pageNumber' initialValue={0}>
                            <Input/>
                        </Form.Item>

                        <Button type='primary' onClick={errorBuilderSubmit}>Save</Button>
                    </Form>
                </Flex>

                {/* STEP BUILDER */}

                <Flex vertical gap={8} style={{ width: '50%', minWidth: 500 }}>
                    <Typography.Title level={2}>Step Builder</Typography.Title>
                    <Form
                        form={formStep}
                        layout='vertical'
                        style={{ flexGrow: 1 }}
                        onValuesChange={onFormStepChange}
                    >
                        <Form.Item label='Step' name='step'>
                            <Select
                                options={getStepsByType(reportType).map(step => ({ label: step.title, value: step.key }))}
                            />
                        </Form.Item>
                        {formStepState.step && (
                            <Form.Item label='Explanation Message Template' name='explanationTemplate'>
                                <Select
                                    options={activeStepStep?.stepExplanationTemplates.map((template, index) => ({
                                        label: template,
                                        value: index,
                                    }))}
                                />
                            </Form.Item>
                        )}
                        {(formStepState?.explanationTemplate >= 0) && (
                            <Form.Item
                                label='Explanation Message' name='suggestedMessage'
                                initialValue={activeStepStep?.stepExplanationTemplates[formStepState.explanationTemplate]}
                            >
                                <TextArea/>
                            </Form.Item>
                        )}
                        <Button type='primary' onClick={stepBuilderSubmit}>Save</Button>
                    </Form>
                </Flex>
            </Flex>
        </Flex>
    )
}
