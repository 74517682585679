import { BooleanParam, withDefault } from 'use-query-params';

export const AI_CHAT_QUERY_CONFIG = {
    name: 'aiChat',
    type: withDefault(BooleanParam, false),
}

export const SUM_SELECT_QUERY_PARAM = {
    name: 'sumSelect',
    type: withDefault(BooleanParam, false),
}

export const TICK_MARK_QUERY_CONFIG = {
    name: 'tm',
    type: withDefault(BooleanParam, false),
}

export const magicButtonNames = {
    [AI_CHAT_QUERY_CONFIG.name]: 'AI Chat',
    [SUM_SELECT_QUERY_PARAM.name]: 'Sum Select',
    [TICK_MARK_QUERY_CONFIG.name]: 'Tick Mark',
}
