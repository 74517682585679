import React from 'react'

import * as Sentry from '@sentry/react';
import { Button, Flex, Result } from 'antd';
import { message } from 'antd/lib';

import { CriticalErrorPlaceholderProps } from './CriticalErrorPlaceholder.types'

const ErrorMessage = () => {
    return (
        <Flex style={{ height: '100%', width: '100%' }} align='center' justify='center'>
            <Result
                status='warning'
                title='It seems something broke. Don’t worry, we’ll fix it soon.'
                extra={(
                    <Button
                        type='primary' onClick={() => {
                            window.location.reload()
                        }}
                    >
                            Reload
                    </Button>
                )}
            />
        </Flex>
    )
}

// Don't want to spam user
let loadingMessageShown = false

export const LocalErrorBoundary = (props: CriticalErrorPlaceholderProps) => {
    const { children ,noContent } = props

    return (
        <Sentry.ErrorBoundary
            fallback={noContent ? <></> : <ErrorMessage/>} onError={() => {
                if(noContent && !loadingMessageShown) {
                    message.warning('It seems there was an issue loading the page. Please try reloading.')
                    loadingMessageShown = true
                }
            }}
        >
            {children}
        </Sentry.ErrorBoundary>
    )
}
