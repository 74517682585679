import { Menu, Layout, Avatar, Dropdown, MenuProps, Flex, Space, Typography } from 'antd';

const { Header: AntHeader } = Layout;
const { Text, Link } = Typography;

import { useLocation, useNavigate } from 'react-router-dom';

import { useViewerDocument } from '@/hooks/useViewerDocument.ts';

import { HeaderProps } from './Header.types'
import { AuthData, AuthDataContext } from '../../components/containers/AuthContext';
import { ROUTES_CONFIG, ROUTES_CONFIG_REG_REP } from '../../constants/routes.ts';

import { useContext } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

export const Header = (props: HeaderProps) => {
    const authData = useContext<AuthData>(AuthDataContext)

    const navigate = useNavigate()
    const location = useLocation()
    const { logout, loginWithRedirect } = useAuth0()

    const regRepOn = authData?.company?.data()?.features?.includes('showRegRep') ?? false
    const reviewerOn = authData?.company?.data()?.features?.includes('showReviewer') ?? false
    
    const items: MenuProps['items'] = [
        {
            key: 'logout',
            danger: true,
            label: 'Log Out',
            onClick: () => {
                logout( {
                    logoutParams: {
                        returnTo: window.location.origin,
                    },
                })
            },
        },
    ];
    const HEADER_NAV_ITEMS: unknown[] = []

    if(reviewerOn) {
        HEADER_NAV_ITEMS.push({
            key: ROUTES_CONFIG.REVIEW.path,
            label: ROUTES_CONFIG.REVIEW.title,
        })
    }

    if(regRepOn) {
        HEADER_NAV_ITEMS.push({
            key: ROUTES_CONFIG_REG_REP.REG_REP.path,
            label: ROUTES_CONFIG_REG_REP.REG_REP.title,
        })
    }
    
    // KEEP IT HERE: Hack for reset pdfInstance when user leave the page. Hook shold be called outside of the report page to make it work.
    useViewerDocument()
    
    return (
        <AntHeader
            style={{ display: 'flex', alignItems: 'center', background: 'white', padding: 16, justifyContent: 'center' }}
        >
            <Flex style={{ paddingRight: '20px' }}>
                <svg
                    height='34' viewBox='0 0 312 126'
                    fill='none' xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        d='M57.5232 14.4734L16.9637 39.1626L59.0108 63.0014V111.529L99.5703 86.8401V39.1652L57.5232 14.4734Z'
                        fill='white'
                    />
                    <path
                        fillRule='evenodd' clipRule='evenodd'
                        d='M103.288 36.9265L102.547 35.601C101.592 33.9983 100.246 32.6675 98.6338 31.7315L63.1513 11.2208C61.5444 10.2874 59.721 9.79326 57.8636 9.79065H56.3298C54.4724 9.79326 52.649 10.2874 51.0421 11.2208L15.5596 31.7864C13.958 32.7146 12.625 34.0479 11.6989 35.6559L10.958 36.9814C10.0268 38.5919 9.53374 40.4195 9.53113 42.281V83.3547C9.53374 85.2162 10.0268 87.0438 10.958 88.6543L11.6989 89.9799C12.6484 91.5668 13.9762 92.895 15.5596 93.8493L51.0943 114.36C52.6934 115.314 54.522 115.808 56.382 115.79H57.8636C59.721 115.788 61.5444 115.293 63.1513 114.36L98.6338 93.797C100.251 92.8898 101.589 91.5512 102.495 89.9276L103.288 88.602C104.208 86.9863 104.699 85.1613 104.714 83.3024V42.2287C104.712 40.3672 104.219 38.5396 103.288 36.9291V36.9265ZM56.3298 20.3898H57.8636L88.8515 38.3043L57.1228 56.6424L25.3941 38.3043L56.3298 20.3898ZM62.4105 102.54L93.3461 84.6254L94.1391 83.2998V47.4708L62.4105 65.8611V102.537V102.54Z'
                        fill='#141414'
                    />
                    <path
                        d='M78.9111 68.4C76.9311 68.4 75.3111 69.0924 74.0511 70.477C72.7911 71.8616 72.1611 73.5693 72.1611 75.6001C72.1611 77.6308 72.7911 79.3385 74.0511 80.7231C75.3111 82.1077 76.9311 82.8 78.9111 82.8C80.8911 82.8 82.5111 82.1077 83.7711 80.7231C85.0311 79.3385 85.6611 77.6308 85.6611 75.6001C85.6611 73.5693 85.0311 71.8616 83.7711 70.477C82.5111 69.0924 80.8911 68.4 78.9111 68.4Z'
                        fill='#141414'
                    />
                    <path
                        d='M279.802 97.9999C278.426 97.9999 277.288 97.9205 276.389 97.7618C275.542 97.656 274.669 97.4708 273.77 97.2062L276.151 86.8888C276.521 86.9946 276.918 87.1004 277.341 87.2062C277.818 87.3121 278.267 87.365 278.691 87.365C280.542 87.365 281.918 86.9946 282.818 86.2539C283.77 85.566 284.458 84.6137 284.881 83.3967L285.357 81.6507L270.278 43.3967H283.929L288.691 58.6348C289.273 60.4867 289.775 62.3385 290.198 64.1904C290.622 65.9893 291.071 67.8941 291.548 69.9047H291.865C292.288 67.9999 292.685 66.1216 293.056 64.2697C293.479 62.4179 293.929 60.5396 294.405 58.6348L298.373 43.3967H311.389L298.056 82.4443C296.997 85.1428 295.913 87.4708 294.802 89.4285C293.743 91.3861 292.5 92.9999 291.071 94.2697C289.643 95.5396 288.003 96.4655 286.151 97.0475C284.352 97.6824 282.236 97.9999 279.802 97.9999Z'
                        fill='#141414'
                    />
                    <path
                        d='M263.512 84.1905C261.183 84.1905 259.226 83.8465 257.639 83.1587C256.104 82.418 254.861 81.3862 253.908 80.0635C252.956 78.7407 252.268 77.1799 251.845 75.3809C251.474 73.5291 251.289 71.4921 251.289 69.2698V28H264.94V69.746C264.94 71.1217 265.178 72.0476 265.654 72.5238C266.183 73 266.686 73.2381 267.162 73.2381C267.427 73.2381 267.639 73.2381 267.797 73.2381C268.009 73.2381 268.273 73.1852 268.591 73.0794L270.178 83.0794C269.437 83.3968 268.512 83.6614 267.4 83.873C266.342 84.0846 265.046 84.1905 263.512 84.1905Z'
                        fill='#141414'
                    />
                    <path
                        d='M205.937 83.0793L217.842 62.4443L206.572 43.3967H221.175L224.032 48.9523C224.561 50.0634 225.09 51.201 225.619 52.365C226.148 53.529 226.678 54.6666 227.207 55.7777H227.524C227.894 54.6666 228.291 53.529 228.715 52.365C229.138 51.201 229.535 50.0634 229.905 48.9523L231.81 43.3967H245.937L234.667 64.0316L246.572 83.0793H231.969L228.794 77.5237C228.212 76.4126 227.63 75.275 227.048 74.111C226.466 72.947 225.884 71.8094 225.302 70.6983H224.984C224.561 71.8094 224.111 72.947 223.635 74.111C223.159 75.2221 222.709 76.3597 222.286 77.5237L220.064 83.0793H205.937Z'
                        fill='#141414'
                    />
                    <path
                        d='M188.617 84.0316C185.707 84.0316 183.008 83.5819 180.521 82.6824C178.035 81.73 175.865 80.3808 174.014 78.6348C172.215 76.8359 170.786 74.6401 169.728 72.0475C168.67 69.4549 168.141 66.5184 168.141 63.238C168.141 60.0105 168.67 57.1269 169.728 54.5872C170.839 51.9946 172.267 49.7988 174.014 47.9999C175.812 46.2009 177.849 44.8253 180.125 43.8729C182.4 42.9205 184.754 42.4443 187.188 42.4443C190.098 42.4443 192.638 42.947 194.807 43.9523C196.976 44.9576 198.775 46.3332 200.204 48.0793C201.633 49.8253 202.691 51.8623 203.379 54.1904C204.119 56.5184 204.49 59.0052 204.49 61.6507C204.49 62.8676 204.41 64.0052 204.252 65.0634C204.146 66.1216 204.04 66.8888 203.934 67.365H181.315C181.95 69.7459 183.088 71.4126 184.728 72.365C186.368 73.2644 188.299 73.7142 190.521 73.7142C191.897 73.7142 193.22 73.529 194.49 73.1586C195.812 72.7353 197.188 72.1269 198.617 71.3332L203.061 79.4285C200.892 80.9628 198.484 82.1269 195.839 82.9205C193.246 83.6613 190.839 84.0316 188.617 84.0316ZM181.156 58.4761H192.902C192.902 56.9417 192.506 55.6189 191.712 54.5078C190.971 53.3438 189.569 52.7618 187.506 52.7618C185.971 52.7618 184.622 53.2115 183.458 54.111C182.347 55.0105 181.58 56.4655 181.156 58.4761Z'
                        fill='#141414'
                    />
                    <path
                        d='M126.011 83.0791V43.3966H137.122L138.075 48.1585H138.392C139.979 46.6241 141.778 45.3014 143.789 44.1902C145.852 43.0262 148.233 42.4442 150.932 42.4442C155.27 42.4442 158.419 43.9257 160.376 46.8887C162.334 49.7987 163.313 53.7141 163.313 58.6347V83.0791H149.662V60.3807C149.662 57.841 149.344 56.1479 148.71 55.3014C148.075 54.4548 147.069 54.0315 145.694 54.0315C144.424 54.0315 143.366 54.2961 142.519 54.8252C141.672 55.3543 140.72 56.095 139.662 57.0474V83.0791H126.011Z'
                        fill='#141414'
                    />
                </svg>
            </Flex>
            <Flex style={{ flexGrow: 1, alignItems: 'center' }}>
                <Menu
                    mode='horizontal'
                    onClick={(el) => {
                        navigate(el.key)
                    }}
                    // FIXME: Hack here to make menu items active. Won't work with routes with variables
                    defaultSelectedKeys={[location.pathname]}
                    items={HEADER_NAV_ITEMS}
                    style={{ flexShrink: 0, width: 500 }}
                />
            </Flex>

            <Space>
                <Flex vertical align='flex-end'>
                    <Text strong>{authData.user.displayName}</Text>
                    <Text>{authData.company.data().name}</Text>
                </Flex>
                <Dropdown
                    menu={{ items }}
                    placement='bottomRight'
                    trigger={['click']}
                >
                    <Avatar
                        // TODO: Fallback to avatar generator service
                        size='large' shape='square' src={authData.user.avatarUrl}
                        style={{ cursor: 'pointer' }}
                    />
                </Dropdown>
            </Space>
        </AntHeader>
    )
}
